<template>
  <div :class="utdLogoClass">
    <a class="wk-logo"
       role="img"
       aria-label="UpToDate logo"
       href="/contents/search"
       tabindex="0">
      <img :src="wkWheelIconSrc" class="wk-wheel" alt="Wolters Kluwer"><!--
   --><img :src="utdLogoSrc" class="utd-logo" alt="UpToDate">
    </a>
    <span v-if="showCoBranding"
          id="cobrandingLogoRes"
          class="cobrandingLogoRes">
      <a :href="cobrandingAnchorHref"
         role="img"
         aria-label="Co-branding logo"
         target="_blank"
         tabindex="0"
         rel="noopener noreferrer">
        <img :src="cobrandingImageUrl" alt="Co-brand Logo">
      </a>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_APP } from '_acaSrc/utility/constants';

export default {
    props: {
        darkLogo: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters('app', [
            'isUccState',
            'isCobrandingEnabled',
            'cobrandingImageUrl',
            'cobrandingAnchorHref'
        ]),
        ...mapGetters('search', [ 'searchBarHeader' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('feature', [ 'isHomepageRedesign2024' ]),
        showCoBranding() {
            if (this.isHomepageRedesign2024) {
                return this.isDesktopView &&
                    this.isCobrandingEnabled &&
                    !this.searchBarHeader;
            }
            else {
                return this.isCobrandingEnabled && !this.searchBarHeader;
            }
        },
        utdLogoClass() {
            return this.isUccState
                ? C_APP.LOGO_FILENAMES.UCC
                : '';
        },
        utdLogoSrc() {
            const directory = this.isHomepageRedesign2024 && !this.darkLogo ?
                '/app/utd-redesign' :
                '/app/utd-menu';
            return `${directory}/utd${this.isUccState ? 'cc' : ''}-logo.svg`;
        },
        wkWheelIconSrc() {
            return this.isHomepageRedesign2024 && !this.darkLogo ?
                '/app/utd-redesign/wk-wheel-icon-white.svg' :
                '/app/utd-menu/wk-wheel-icon.svg';
        }
    }
};
</script>

<style lang="less">
@import './UtdLogo.less';

&.utdWkHomePage2024 {
  .isSmallScreen&,
  .isMobile&,
  .isMobileOnDesktop&,
  .isTablet& {
    .wk-logo {
      .wk-wheel {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      .utd-logo {
        height: 36px;
        width: auto;
      }
    }
  }
}
</style>
