<template>
  <div v-if="showShortcuts"
       class="search-personalized-shortcuts">
    <ul role="menu">
      <li :id="`homeSearchPanel_history`" role="menuitem">
        <a :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.HISTORY}`"
           @click="showPersonalizationModal($event, myUtdTabs.HISTORY)">
          {{ $t('MYUPTODATE.HISTORY') }}
        </a>
      </li>
      <li :id="`homeSearchPanel_mostViewed`" role="menuitem">
        <a :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.MOST_VIEWED}`"
           @click="showPersonalizationModal($event, myUtdTabs.MOST_VIEWED)">
          {{ $t('MYUPTODATE.MOST_VIEWED') }}
        </a>
      </li>
      <li :id="`homeSearchPanel_bookmark`" role="menuitem">
        <a :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.BOOKMARKS}`"
           @click="showPersonalizationModal($event, myUtdTabs.BOOKMARKS)">
          {{ $t('MYUPTODATE.BOOKMARKS') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { C_EVENTS, C_MYUTD_TABS } from '_acaSrc/utility/constants';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'PersonalizedShortcuts',
    data() {
        return {
            myUtdTabs: C_MYUTD_TABS
        };
    },
    computed:{
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('app', [ 'isUccState' ]),
        showShortcuts() {
            return this.userLoggedIn
              && this.permissions.myUpToDate
              && !this.isUccState;
        }
    },
    watch: {
        showShortcuts(data) {
            this.$emit('show-shortcuts', data);
        }
    },
    mounted() {
        this.$emit('show-shortcuts', this.showShortcuts);
    },
    methods: {
        ...mapActions('app', [ 'publish' ]),
        showPersonalizationModal(event, tab) {
            this.publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
            this.publish({
                eventName: 'wkutd.open-myuptodate-modal',
                eventData: { event, tab }
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utdWkHomePage2024 .search-personalized-shortcuts {
  color: @WKCE-WHITE;
  padding-right: 90px;
  width: 339px;
  margin-left: auto;

  a {
    font-size: 14px;
    line-height: 24px;
    color: @WKCE-WHITE;
  }

  ul {
    .ds1-utd-list-style-none();
    .ds1-pa-0();
    .ds1-ma-0();
    .ds1-utd-weight-500();
    .ds1-utd-text-right();
    justify-content: flex-end;
    display: flex;
    font-size: 14px;
    line-height: 24px;

    li {
      position: relative;
      padding: 0 10px;

      &:first-child {
       .ds1-pl-0();
      }

      &:not(:first-child) {
        padding: 0 10px ;
      }

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background-color: @WKCE-GRAY-TINT2;
      }

      &:last-child{
        .ds1-pr-0();;
      }
    }
  }

  .isSmallScreen&,
  .isMobileOnDesktop&,
  .mobileonDesktop&,
  .isTablet&,
  .isMobile& {
    .ds1-pr-0();
    .ds1-pv-1();
    width: 100%;
    margin-top: 28px;

    ul {
      width: 100%;
      justify-content: center;
    }
  }
}

 @media only screen and (min-width: 768px) {
  .utdWkHomePage2024 .search-widget {
    ul li:not(:first-child) {
      .ds1-ph-2();
    }

    ul li:first-child {
      .ds1-pr-2();
    }

    ul li:last-child {
      .ds1-pr-0();
    }
  }
}
</style>