/* eslint-disable complexity */
import { stringifyQuery } from './query';

const trailingSlashRE = /\/?$/;

export function createRoute(record, location, redirectedFrom, router) {
    const stringifyQuery = router && router.options.stringifyQuery;

    let query = location.query || {};
    try {
        query = clone(query);
    }
    catch (e) {
        // no-op
    }

    const route = {
        name: location.name || (record && record.name),
        meta: (record && record.meta) || {},
        path: location.path || '/',
        hash: location.hash || '',
        query,
        params: location.params || {},
        fullPath: getFullPath(location, stringifyQuery),
        matched: record ? formatMatch(record) : []
    };
    if (redirectedFrom) {
        route.redirectedFrom = getFullPath(redirectedFrom, stringifyQuery);
    }
    return Object.freeze(route);
}

function clone(value) {
    if (Array.isArray(value)) {
        return value.map(clone);
    }
    else if (value && typeof value === 'object') {
        const res = {};
        for (const key in value) {
            res[key] = clone(value[key]);
        }
        return res;
    }
    else {
        return value;
    }
}

function formatMatch(record) {
    const res = [];
    while (record) {
        res.unshift(record);
        record = record.parent;
    }
    return res;
}

// the starting route that represents the initial state
export const START = createRoute(null, {
    path: '/'
});


function getFullPath({ path, query = {}, hash = '' }, _stringifyQuery) {
    const stringify = _stringifyQuery || stringifyQuery;
    return (path || '/') + stringify(query) + hash;
}

export function isSameRoute(a, b, onlyPath) {
    if (b.url === '^') { // Is target same as base route?
        return a.url === '^';
    }
    else if (!b) {
        return false;
    }
    else if (a.url && b.url) {
        return a.url.replace(trailingSlashRE, '') === b.url.replace(trailingSlashRE, '')
            && (onlyPath || a.hash === b.hash && isObjectEqual(a.query, b.query));
    }
    else if (a.name && b.name) {
        return (
            a.name === b.name
            && (onlyPath || (
                a.hash === b.hash
            && isObjectEqual(a.query, b.query)
            && isObjectEqual(a.params, b.params))
            )
        );
    }
    return false;

}

function isObjectEqual(a = {}, b = {}) {
    // handle null value #1566
    if (!a || !b) {
        return a === b;
    }
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    if (aKeys.length !== bKeys.length) {
        return false;
    }
    return aKeys.every((key, i) => {
        const aVal = a[key];
        const bKey = bKeys[i];
        if (bKey !== key) {
            return false;
        }
        const bVal = b[key];
        // query values can be null and undefined
        if (aVal == null || bVal == null) {
            return aVal === bVal;
        }
        // check nested equality
        if (typeof aVal === 'object' && typeof bVal === 'object') {
            return isObjectEqual(aVal, bVal);
        }
        return String(aVal) === String(bVal);
    });
}

export function isIncludedRoute(current, target) {
    return (
        current.url.replace(trailingSlashRE, '/').indexOf(
            target.url.replace(trailingSlashRE, '/')
        ) === 0
            && (!target.hash || current.hash === target.hash)
                && queryIncludes(current.query, target.query)
    );
}

function queryIncludes(current, target) {
    for (const key in target) {
        if (!(key in current)) {
            return false;
        }
    }
    return true;
}

export function handleRouteEntered(route) {
    if (!route.matched) {
        return;
    }
    for (let i = 0; i < route.matched.length; i++) {
        const record = route.matched[i];
        for (const name in record.instances) {
            const instance = record.instances[name];
            const cbs = record.enteredCbs[name];
            if (!instance || !cbs) {
                continue;
            }
            delete record.enteredCbs[name];
            for (let i = 0; i < cbs.length; i++) {
                if (!instance._isBeingDestroyed) {
                    cbs[i](instance);
                }
            }
        }
    }
}