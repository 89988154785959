<template>
  <div v-if="showFeedbackGuide"
       class="utd-feedback-wrapper">
    <utd-button class="utd-pendo-feedback"
                button-size="small"
                button-style="text"
                aria-label="Feedback"
                tabindex="0"
                @click.prevent="launchFeedbackGuide">Feedback
    </utd-button>
  </div>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        UtdButton
    },
    props: {
        feedbackData: Object
    },
    computed: {
        ...mapGetters('pendo', [ 'showFeedbackGuide' ])
    },
    methods: {
        ...mapActions('pendo', [ 'launchGuide' ]),
        launchFeedbackGuide() {
            this.launchGuide({
                guideName: 'FeedbackGuide',
                guideData: {
                    ...this.feedbackData
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-feedback-wrapper {
  .ds1-inline-block();

  &.bg-white {
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  }

  &.bg-transparent {
    background-color: transparent;
  }

  :deep(.utd-button) {
    .ds1-utd-size-1();
  }

  &.key-points-feedback {
    .utd-button {
      height: 32px;
    }
  }
}
</style>
