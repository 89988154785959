<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="qap-panel qap-formulation ds1-qap-container wk-js2">
    <div class="qap-header">
      <div class="qap-title wkce-icon-medicine"
           role="heading"
           aria-level="2">{{ result.title }}</div>
    </div>
    <utd-tabs class="with-divider" :active-tab="activeTab">
      <utd-tab v-for="(content, contentIdx) in result.qapContents"
               :key="contentIdx"
               :title="$t(content.translationKey || content.contentLabel)"
               :tab-id="`${contentIdx}`">
        <div class="qap-section-subtext"
        >Related formulations are presented in the following topics:</div>
        <section v-for="(section, sectionIdx) in content.sections"
                 :key="sectionIdx"
                 class="qap-content qap-formulation-section qap-section">
          <div v-if="isLinksSection(section)"
               class="qap-section-container">
            <dl class="qap-list">
              <dt class="qap-section-title"
                  v-html="getSectionTitle(section.title)" />
              <dd v-for="(item, itemIdx) in section.items"
                  :key="itemIdx"
                  class="qap-list-item">
                <a class="qap-link"
                   :class="item.class"
                   :href="setUrlSource(item.url)"
                   v-html="item.title" />
              </dd>
            </dl>
          </div>
          <qap-drug-interactions v-if="isDrugInteractionSection(section)"
                                 :result="section"
                                 :source="source" />
          <qap-rx-transitions v-if="isShowRxTransitionsSection(section)"
                              :result="section"
                              :source="source" />
        </section>
      </utd-tab>
    </utd-tabs>
  </div>
</template>

<script>
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import QapDrugInteractions from './QapDrugInteractions.vue';
import QapRxTransitions from './QapRxTransitions.vue';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { C_QAP } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdTabs,
        UtdTab,
        QapDrugInteractions,
        QapRxTransitions
    },
    props: {
        result: Object,
        source: String
    },
    data() {
        return {
            activeTab: 0
        };
    },
    computed: {
        ...mapGetters('qap', [ 'qapActiveTab' ])
    },
    mounted() {
        this.activeTab = this.qapActiveTab;
    },
    methods: {
        isDrugInteractionSection(section) {
            return section.type === C_QAP.SECTION.DRUG_INTERACTIONS;
        },
        isShowRxTransitionsSection(section) {
            return section.type === C_QAP.SECTION.RX_TRANSITIONS;
        },
        isLinksSection(section) {
            return section.type === C_QAP.SECTION.LINKS;
        },
        setUrlSource(url) {
            return setQueryParamValue(url, 'source', this.source);
        },
        getSectionTitle(title) {
            return title.indexOf(': ') > -1
                ? title
                : this.$t(title);
        }
    }
};
</script>

<style lang="less" scoped>
@import "../vue-qap.less";

.qap-panel .wk-tab-inner-content {
  .ds1-pt-2();
}

.qap-title {
    .ds1-ml-4();
    text-align: left;
}

.wkce-icon-medicine::before {
  .ds1-mr-1();
  margin-left: -24px;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.qap-section-container {
  .ds1-ph-2();
  .ds1-pb-2();
}

.qap-section-subtext {
  .ds1-pb-2();
  .ds1-ph-2();
  color: @DS1-UTD-TOPIC-TEXT-COLOR;
}

</style>