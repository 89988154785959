import optimizelySdk from '@optimizely/optimizely-sdk';
import {
    C_DOMAINS,
    C_EVENTS,
    C_TOPIC
} from '_acaSrc/utility/constants';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { optimizelyAggregateAttributes } from '_acaSrc/utility/Feature';
import Logger from '_acaSrc/utility/Logger';
import MathUtils from '_acaSrc/utility/math';
import PubSub from '_acaSrc/utility/PubSub';
import IndexManager from '_acaSrc/utility/IndexManager';

const state = {
    optimizelyRolloutsId: null,
    optimizelyRolloutsInstalled: false,
    featureClient: null,
    hasStartedInitialize: null,
    features: {},
    showPrintLink: false,
    showSkipLink: false,
    hideNavBar: false,
    showAthensLink: false,
    showDrugInteractionsApp: false,
    showBackToSearch: false,
    showFormulinkContent: false,
    isHeaderRedesign: false,
    isHasContentShare: false,
    hasPathwaysCsuNotes: false,
    hasPathwaysContent: false,
    issueGuestPass: false,
    topicRedesign: false,
    isShowLabI: false,
    isShowRxTransitions: false,
    isResponsiveDesktopEnabled: false,
    isLoginRedesignEnabled: false,
    isTwoFactorEmailVerification: false,
    optimizelySet: false,
    isHomepageRedesign2024: false,
    isContentFeedbackMigration: false,
    hasAiSearchFlag: false,
    aiSearchFlagSet: false
};

export const SET_OPTIMIZELY_ROLLOUTS_ID = 'SET_OPTIMIZELY_ROLLOUTS_ID';
export const SET_OPTIMIZELY_ROLLOUTS_INSTALLED = 'SET_OPTIMIZELY_ROLLOUTS_INSTALLED';
export const SET_FEATURE_CLIENT = 'SET_FEATURE_CLIENT';
export const SET_HAS_STARTED_INITIALIZE = 'SET_HAS_STARTED_INITIALIZE';
export const SET_FEATURE_VALUE = 'SET_FEATURE_VALUE';
export const SET_SHOW_PRINT_LINK = 'SET_SHOW_PRINT_LINK';
export const SET_SHOW_SKIP_LINK = 'SET_SHOW_SKIP_LINK';
export const SET_HIDE_NAV_BAR = 'SET_HIDE_NAV_BAR';
export const SET_SHOW_ATHENS_LINK = 'SET_SHOW_ATHENS_LINK';
export const SET_SHOW_DRUG_INTERACTIONS = 'SET_SHOW_DRUG_INTERACTIONS';
export const SET_SHOW_BACK_TO_SEARCH = 'SET_SHOW_BACK_TO_SEARCH';
export const SET_FORMULINK_CONTENT = 'SET_FORMULINK_CONTENT';
export const SET_DI_APP = 'SET_DI_APP';
export const SET_HAS_PATHWAYS_CSU_NOTES = 'SET_HAS_PATHWAYS_CSU_NOTES';
export const SET_HAS_PATHWAYS_CONTENT = 'SET_HAS_PATHWAYS_CONTENT';
export const SET_IS_HEADER_REDESIGN = 'SET_IS_HEADER_REDESIGN';
export const SET_HAS_CONTENT_SHARE = 'SET_HAS_CONTENT_SHARE';
export const SET_TOPIC_REDESIGN = 'SET_TOPIC_REDESIGN';
export const SET_GUEST_PASS = 'SET_GUEST_PASS';
export const SET_SHOW_LAB_INTERPRETATION = 'SET_SHOW_LAB_INTERPRETATION';
export const SET_SHOW_RX_TRANSITIONS = 'SET_SHOW_RX_TRANSITIONS';
export const SET_RESPONSIVE_DESKTOP_ENABLED = 'SET_RESPONSIVE_DESKTOP_ENABLED';
export const SET_TWO_FACTOR_EMAIL_ENABLED = 'SET_TWO_FACTOR_EMAIL_ENABLED';
export const SET_OPTIMIZELY_DONE_STATE = 'SET_OPTIMIZELY_DONE_STATE';
export const SET_LOGIN_REDESIGN_ENABLED = 'SET_LOGIN_REDESIGN_ENABLED';
export const SET_IS_HOMEPAGE_REDESIGN = 'SET_IS_HOMEPAGE_REDESIGN';
export const SET_IS_CONTENT_FEEDBACK_MIGRATION = 'SET_IS_CONTENT_FEEDBACK_MIGRATION';
export const SET_HAS_AI_SEARCH_FLAG = 'SET_HAS_AI_SEARCH_FLAG';


export const mutations = {
    [SET_OPTIMIZELY_ROLLOUTS_ID]: (state, optimizelyRolloutsId) =>
        state.optimizelyRolloutsId = optimizelyRolloutsId,
    [SET_OPTIMIZELY_ROLLOUTS_INSTALLED]: (state, optimizelyRolloutsInstalled) =>
        state.optimizelyRolloutsInstalled = optimizelyRolloutsInstalled,
    [SET_FEATURE_CLIENT]: (state, featureClient) =>
        state.featureClient = featureClient,
    [SET_HAS_STARTED_INITIALIZE]: (state, hasStartedInitialize) =>
        state.hasStartedInitialize = hasStartedInitialize,
    [SET_FEATURE_VALUE]: (state, payload) => {
        const { feature, value } = payload;
        state.features[feature] = value;
    },
    [SET_SHOW_PRINT_LINK]: (state, data) => {
        state.showPrintLink = false;
        if ('print-view-link' in data) {
            if (data['print-view-link']) {
                state.showPrintLink = true;
            }
        }
    },
    [SET_SHOW_SKIP_LINK]: (state, showSkipLink) => state.showSkipLink = showSkipLink,
    [SET_HIDE_NAV_BAR]: (state, hideNavBar) => state.hideNavBar = hideNavBar,
    [SET_SHOW_ATHENS_LINK]: (state, showAthensLink) => state.showAthensLink = showAthensLink,
    [SET_SHOW_DRUG_INTERACTIONS]: (state, show) => state.showDrugInteractionsApp = show,
    [SET_SHOW_BACK_TO_SEARCH]: (state, show) => state.showBackToSearch = show,
    [SET_FORMULINK_CONTENT]: (state, content) => state.showFormulinkContent = content,
    [SET_DI_APP]: (state, data) => {
        if ('responsive-app-drug-interaction-app' in data) {
            if (data['responsive-app-drug-interaction-app']) {
                state.showDrugInteractionsApp = true;
            }
        }
    },
    [SET_HAS_PATHWAYS_CSU_NOTES]: (state, data) => {
        state.hasPathwaysCsuNotes = 'csu-notes' in data && data['csu-notes'];
    },
    [SET_HAS_PATHWAYS_CONTENT]: (state, data) => {
        state.hasPathwaysContent = 'taskflow-content' in data && data['taskflow-content'];
    },
    [SET_IS_HEADER_REDESIGN]: (state, data) => {
        state.isHeaderRedesign = false;
        if ('isHeaderRedesign' in data && !!data.isHeaderRedesign) {
            state.isHeaderRedesign = true;
        }

        new IndexManager().bodyCss.setOrClear('utdWkHeader', state.isHeaderRedesign);
    },
    [SET_IS_HOMEPAGE_REDESIGN]: (state, data) => {
        state.isHomepageRedesign2024 = data.isHomepageRedesign || false;
        new IndexManager().bodyCss.setOrClear('utdWkHomePage2024', state.isHomepageRedesign2024);
    },
    [SET_IS_CONTENT_FEEDBACK_MIGRATION]: (state, data) => {
        state.isContentFeedbackMigration = !!data.isContentFeedbackMigration;
    },
    [SET_HAS_AI_SEARCH_FLAG]: (state, data) => {
        state.hasAiSearchFlag = data.isNlpSearchEnabled || false;
        state.aiSearchFlagSet = true;
        new IndexManager().bodyCss.setOrClear('aiInUtd', state.hasAiSearchFlag);
    },
    [SET_HAS_CONTENT_SHARE]: (state, data) => {
        state.isHasContentShare = false;
        if ('isHasContentShare' in data) {
            state.isHasContentShare = data.isHasContentShare;
        }
    },
    [SET_TOPIC_REDESIGN]: (state, data) => {
        state.topicRedesign = false;
        if ('isTopicRedesign' in data && !!data.isTopicRedesign) {
            state.topicRedesign = true;
        }
        new IndexManager().bodyCss.setOrClear('utdWkTopic', state.topicRedesign);
    },
    [SET_GUEST_PASS]: (state, value) => {
        state.issueGuestPass = value;
    },
    [SET_RESPONSIVE_DESKTOP_ENABLED]: (state, data) => {
        state.isResponsiveDesktopEnabled = false;
        if ('responsive-app-desktop' in data) {
            if (data['responsive-app-desktop']) {
                state.isResponsiveDesktopEnabled = true;
            }
        }
    },
    [SET_LOGIN_REDESIGN_ENABLED]: (state, data) => {
        state.isLoginRedesignEnabled = false;
        if ('isLoginRedesign' in data) {
            state.isLoginRedesignEnabled = data.isLoginRedesign;
        }
    },
    [SET_TWO_FACTOR_EMAIL_ENABLED]: (state, data) => {
        state.isTwoFactorEmailVerification = false;
        if ('isTwoFactorEmailVerification' in data) {
            state.isTwoFactorEmailVerification = data.isTwoFactorEmailVerification;
        }
    },
    [SET_SHOW_LAB_INTERPRETATION]: (state, data) => {
        state.isShowLabI = false;
        if ('isLabInterpretation' in data && !!data.isLabInterpretation) {
            state.isShowLabI = true;
        }
    },
    [SET_SHOW_RX_TRANSITIONS]: (state, data) => {
        state.isShowRxTransitions = false;
        if ('isRxTransitions' in data && !!data.isRxTransitions) {
            state.isShowRxTransitions = true;
        }
    },
    [SET_OPTIMIZELY_DONE_STATE]: (state, data) => {
        state.optimizelySet = data;
    }
};

export const getters = {
    optimizelyRolloutsId: state => state.optimizelyRolloutsId,
    optimizelyRolloutsInstalled: state => state.optimizelyRolloutsInstalled,
    featureClient: state => state.featureClient,
    hasStartedInitialize: state => state.hasStartedInitialize,
    showPrintLink: state => state.showPrintLink,
    showSkipLink: state => state.showSkipLink,
    hideNavBar: state => state.hideNavBar,
    featureValue: state => feature => state.features[feature],
    featureAttributes: (state, getters, rootState, rootGetters) => {
        return {
            accountId: `${rootGetters['profile/userProfileAccount'].id}`,
            accountIdSource: `${rootGetters['profile/userProfileVisitor'].accountIdSource}`,
            // eslint-disable-next-line max-len
            accountCountryCode: `${rootGetters['profile/userProfileAccount'].primaryAccountCountryCode}`,
            activeAccountIds: rootGetters['profile/userProfileVisitor'].activeAccountIds
                ? rootGetters['profile/userProfileVisitor'].activeAccountIds.toString() : '',
            browserName: `${rootGetters['device/browserData'].name}`,
            browserNVersion: rootGetters['device/browserData'].nVersion,
            browserOs: `${rootGetters['device/browserData'].os}`,
            browserOsVersion: `${rootGetters['device/browserData'].osVersion}`,
            browserType: `${rootGetters['device/browserData'].type}`,
            browserVendor: `${rootGetters['device/browserData'].vendor}`,
            cmeCredits: rootGetters['profile/userProfileVisitor'].cmeCredits,
            cmeType: `${rootGetters['profile/userProfileVisitor'].cmeType}`,
            deviceSize: `${rootGetters['profile/userProfileVisitor'].deviceSize}`,
            deviceType: `${rootGetters['profile/userProfileVisitor'].deviceType}`,
            environment: `${rootGetters['profile/userProfileVisitor'].environment}`,
            expirationDate: rootGetters['profile/userProfileVisitor'].expirationDate || '',
            expiredAccountId: `${rootGetters['profile/userProfileVisitor'].expiredAccountId}`,
            geoIpCountry: `${rootGetters['profile/userProfileVisitor'].geoIpCountry || ''}`,
            icgContent: rootGetters['profile/userProfileVisitor'].icgContent,
            individualAccountId: `${rootGetters['profile/userProfileVisitor'].individualAccountId}`,
            ipAddress: `${rootGetters['profile/userProfileVisitor'].ipAddress}`,
            isEMRAuthenticated: rootGetters['profile/userProfileVisitor'].isEMRAuthenticated,
            isGuestUser: rootGetters['profile/userProfileVisitor'].isGuestUser,
            isLegacy: rootGetters['profile/userProfileVisitor'].isLegacy,
            isLoggedIn: rootGetters['profile/userProfileVisitor'].isLoggedIn,
            isProspect: rootGetters['profile/userProfileVisitor'].isProspect,
            isRegistered: rootGetters['profile/userProfileVisitor'].isRegistered,
            isRunCampaigns: rootGetters['profile/userProfileVisitor'].isRunCampaigns,
            labInterpretation: rootGetters['profile/userProfileVisitor'].labI,
            localeLanguage: `${rootGetters['profile/userProfileVisitor'].localeLanguage}`,
            onsiteId: `${rootGetters['profile/userProfileVisitor'].onsiteId}`,
            practiceType: `${rootGetters['profile/userProfileVisitor'].practiceType}`,
            productCodes: rootGetters['profile/userProfileAccount'].productCodes
                ? rootGetters['profile/userProfileAccount'].productCodes.toString() : '',
            remoteAccessId: `${rootGetters['profile/userProfileVisitor'].remoteAccessId}`,
            ssrLapsedDate: rootGetters['profile/userProfileVisitor'].ssrLapsedDate || '',
            subscriptionNumbers: rootGetters['profile/userProfileAccount'].subscriptionNumber
                ? rootGetters['profile/userProfileAccount'].subscriptionNumber.toString() : '',
            userCountry: `${rootGetters['profile/userProfileVisitor'].userCountry}`,
            userUtdId: `${rootGetters['profile/userProfileVisitor'].userUtdId}`,
            visitorId: `${rootGetters['profile/userProfileVisitor'].id}`
        };
    },
    showAthensLink: state => state.showAthensLink,
    showDrugInteractionsApp: state => state.showDrugInteractionsApp,
    showBackToSearch: state => state.showBackToSearch,
    showFormulinkContent: state => state.showFormulinkContent,
    isHeaderRedesign: state => state.isHeaderRedesign,
    isHomepageRedesign2024: state => state.isHomepageRedesign2024,
    isContentFeedbackMigration: state => state.isContentFeedbackMigration,
    hasAiSearchFlag: state => state.hasAiSearchFlag,
    aiSearchFlagSet: state => state.aiSearchFlagSet,
    isHasContentShare: state => state.isHasContentShare,
    hasPathwaysCsuNotes: state => state.hasPathwaysCsuNotes,
    hasPathwaysContent: state => state.hasPathwaysContent,
    hasPathwaysAccess: (state, getters, rootState) => {
        return state.hasPathwaysContent
            || rootState.app.enabledPathways.length > 0;
    },
    topicRedesign: state => state.topicRedesign,
    issueGuestPass: state => state.issueGuestPass,
    isShowLabI: state => state.isShowLabI,
    isShowRxTransitions: state => state.isShowRxTransitions,
    isResponsiveDesktopEnabled: state => state.isResponsiveDesktopEnabled,
    isLoginRedesignEnabled: state => state.isLoginRedesignEnabled,
    isTwoFactorEmailVerification: state => state.isTwoFactorEmailVerification,
    isOptimizelySet: state => state.optimizelySet
};

export const actions = {
    initializeOptimizelyWeb: ({ rootGetters }) => {
        // Abort under any of these conditions:
        // - there is no userProfile object
        // - optimizely web script not installed
        // - optimizely is already being initialized
        // - optimizely has already been initialized
        // - window.optimizely object not defined
        if (!rootGetters['profile/userProfile']
            || !rootGetters['profile/userProfile'].optimizelyWebInstalled
            || rootGetters['profile/userProfile'].optimizelyWebInitialized
            || !getWindow().optimizely) {
            return;
        }

        const attributes = {};
        optimizelyAggregateAttributes(attributes, 'account',
            rootGetters['profile/userProfile'].account);
        optimizelyAggregateAttributes(attributes, 'visitor',
            rootGetters['profile/userProfile'].visitor);

        const type = 'user';
        getWindow().optimizely.push({ type, attributes });
        rootGetters['profile/userProfile'].optimizelyWebInitialized = true;
    },
    installOptimizelyWeb: ({ dispatch, rootGetters }, data) => {
        if (data.optimizelyWebId
            && data.optimizelyWebId.trim()
            && rootGetters['device/browserData'].isSupported.optimizely) {
            const scriptElement = getDocument().querySelector('head #optimizelyWebTag');
            if (!scriptElement) {
                try {
                    const webProjectId = Number(data.optimizelyWebId);
                    if (!isNaN(webProjectId)) {
                        const script = getDocument().createElement('script');
                        script.id = 'optimizelyWebTag';
                        script.type = 'text/javascript';
                        script.onload = () => {
                            rootGetters['profile/userProfile'].optimizelyWebInstalled = true;
                            dispatch('initializeOptimizelyWeb');
                        };

                        script.src = `${C_DOMAINS.OPTIMIZELY_CDN}/js/${webProjectId}.js`;
                        getDocument().getElementsByTagName('head')[0].appendChild(script);
                    }

                }
                catch {
                    // Noop
                }
            }
        }
    },
    initializeFeatures({ commit, dispatch }, data) {
        commit(SET_OPTIMIZELY_ROLLOUTS_ID, data.optimizelyRolloutsId);
        return dispatch('installFeatures');
    },
    installFeatures({ dispatch, rootGetters, getters }) {
        return Promise.resolve(true).then(() => {
            return getters.optimizelyRolloutsInstalled
                || (getters.optimizelyRolloutsId
                    && getters.optimizelyRolloutsId.trim()
                    && rootGetters['device/browserData'].isSupported.optimizely
                    && dispatch('injectScriptToHead'))
                || Promise.reject();
        });
    },
    injectScriptToHead({ dispatch, getters }) {
        const scriptElement = getDocument().querySelector('head #optimizelyRolloutsTag');
        if (scriptElement) {
            return Promise.resolve();
        }

        const script = getDocument().createElement('script');
        script.id = 'optimizelyRolloutsTag';
        script.type = 'text/javascript';

        const onLoadPromise = new Promise(resolve => {
            script.onload = () => resolve(dispatch('setupOptimizely'));
        });

        // eslint-disable-next-line max-len
        script.src = `${C_DOMAINS.OPTIMIZELY_CDN}/datafiles/${getters.optimizelyRolloutsId}.json/tag.js`;
        getDocument().getElementsByTagName('head')[0].appendChild(script);

        return onLoadPromise;
    },
    setupOptimizely({ getters, commit }) {
        optimizelySdk.setLogger(optimizelySdk.logging.createLogger());
        optimizelySdk.setLogLevel('warning');

        const data = optimizelySdk.createInstance({
            datafile: getWindow().optimizelyDatafile,
            errorHandler: {
                handleError: error => {
                    Logger.error(error.message);
                }
            }
        });
        commit(SET_FEATURE_CLIENT, data);
        return getters.featureClient.onReady().then(result => {
            if (result) {
                commit(SET_OPTIMIZELY_ROLLOUTS_INSTALLED, true);
                return true;
            }

            Logger.error(`Optimizely Setup Error: [${result.reason}] `);
            return Promise.reject();
        });
    },
    getVisitorId({ getters, rootGetters }) {
        if (!getters.hasStartedInitialize) {
            return Promise.resolve(null);
        }
        return getters.hasStartedInitialize.then(() => {
            if (!getters.featureClient) {
                return Promise.resolve(null);
            }
            return Promise.resolve(rootGetters['profile/userProfileVisitor'].id || '0');
        });
    },
    async isEnabled({ getters, dispatch }, featureName) {
        const visitorId = await dispatch('getVisitorId');
        const user = getters.featureClient.createUserContext(visitorId, getters.featureAttributes);
        const decision = user.decide(featureName);

        return visitorId ? decision.enabled : null;
    },
    async getIntegerValue({ getters, dispatch }, { featureName, featureProperty }) {
        const visitorId = await dispatch('getVisitorId');
        return visitorId
            ? getters.featureClient
                .getFeatureVariableInteger(featureName, featureProperty,
                    visitorId, getters.featureAttributes)
            : null;
    },
    async getStringValue({ getters, dispatch }, { featureName, featureProperty }) {
        const visitorId = await dispatch('getVisitorId');
        return visitorId
            ? getters.featureClient
                .getFeatureVariableString(featureName, featureProperty,
                    visitorId, getters.featureAttributes)
            : null;
    },
    async getBooleanValue({ getters, dispatch }, { featureName, featureProperty }) {
        const visitorId = await dispatch('getVisitorId');
        return visitorId
            ? getters.featureClient
                .getFeatureVariableBoolean(featureName, featureProperty,
                    visitorId, getters.featureAttributes)
            : null;
    },
    optimizelyTimeoutHandler({ getters, rootGetters }, optimizelyResolve) {
        /**
         * Unsubscribes from optimizely pubSubs and resolves promise
         */
        function wrapupOptimizely() {
            new PubSub().unsubscribe(
                C_EVENTS.ASSIGNED_OPTIMIZELY_FEATURES, isOptimizelyResolvedHelper);
            new PubSub().unsubscribe(
                C_EVENTS.APP_DATA_LOADED, isOptimizelyResolvedHelper);
            optimizelyResolve();
        }

        /**
         * A helper function that calls isOptimizelyResolved
         * and is accessible by the PubSub's subscribes
         * @returns { Boolean } Whether or not optimizely has resolved
         */
        function isOptimizelyResolvedHelper() {
            return feature.isOptimizelyResolved({ getters, rootGetters }, wrapupOptimizely);
        }

        new PubSub().subscribe(
            C_EVENTS.ASSIGNED_OPTIMIZELY_FEATURES, isOptimizelyResolvedHelper);
        new PubSub().subscribe(
            C_EVENTS.APP_DATA_LOADED, isOptimizelyResolvedHelper);

        if (isOptimizelyResolvedHelper()) {
            return;
        }
        setTimeout(() => {
            wrapupOptimizely();
            if (!isOptimizelyResolvedHelper()
             && MathUtils.percentChanceHit(C_TOPIC.OPTIMIZELY_TIMEDOUT_LOG_PERCENT_CHANCE)) {
                new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT,
                    { uiElementName: C_TOPIC.OPTIMIZELY_TIMEDOUT_EVENT_NAME });
            }
        }, C_TOPIC.OPTIMIZELY_TIMEOUT_MS);
    },
    /* TODO: Currently this code is unused; once another optimizely feature has been added, we can
             uncomment + remove eslint rule ignore and use this to set optimizely state again */
    // eslint-disable-next-line no-unused-vars
    assignOptimizelyFeatures({ commit, getters, rootGetters }) {
        commit(SET_OPTIMIZELY_DONE_STATE, false);
        // const visitorId = rootGetters['profile/userProfileVisitor'].id || '0';
        // const featureAttributes = getters.featureAttributes;
        // function getIsFeatureEnabled(featureName) {
        //     const user = getters.featureClient.createUserContext(visitorId, featureAttributes);
        //     const decision = user.decide(featureName);

        //     return visitorId ? decision.enabled : null;
        // }

        // Set vuex feature state here once this flow has been re-added

        commit(SET_OPTIMIZELY_DONE_STATE, true);
        new PubSub().publish(C_EVENTS.ASSIGNED_OPTIMIZELY_FEATURES);
    }
};

/**
 * Returns whether or not optimizely is resolved.
 * Unsubscribes from pubSubs and resolves promise if optimizely is all-set.
 * @returns { Boolean } Whether or not optimizely has resolved
 */
export const isOptimizelyResolved = ({ getters, rootGetters }, wrapupOptimizely) => {
    const isProspect = rootGetters['app/isProspectMode']
                    || rootGetters['app/isChinaProspectView'];
    if (getters.isOptimizelySet || isProspect) {
        wrapupOptimizely();
        return true;
    }
    return false;
};

const feature = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    isOptimizelyResolved
};

export default feature;