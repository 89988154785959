<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="qap-panel qap-pathway ds1-qap-container">
    <div class="qap-header">
      <div class="qap-title" role="heading" aria-level="2">Pathways</div>
      <div class="qap-subtitle">Interactive Decision Support</div>
    </div>
    <div class="qap-banner qap-pathway-banner" />
    <div class="qap-content ds1-pl-2">
      <section class="qap-section ds1-pt-2 ds1-pb-1">
        <div v-for="(item, itemIdx) in pathwaySectionItems"
             :key="itemIdx"
             class="qap-section-item ds1-pr-2 ds1-pb-1">
          <a :href="item.url" class="qap-link" v-html="item.label" />
          <span v-if="item.detail"
                class="sectionItemDetail">(<span v-html="item.detail" />)</span>
        </div>
      </section>
      <qap-rx-transitions v-if="isShowRxTransitions" :result="result" :source="source" />
    </div>
  </div>
</template>

<script>
import QapRxTransitions from './QapRxTransitions.vue';

export default {
    components: {
        QapRxTransitions
    },
    props: {
        result: Object,
        source: String
    },
    computed: {
        pathwaySectionItems() {
            return (this.result.qapContents
                && this.result.qapContents.length
                && this.result.qapContents[0].sections
                && this.result.qapContents[0].sections.length
                && this.result.qapContents[0].sections[0].items) || [];
        },
        isShowRxTransitions() {
            return this.result && this.result.rxTransitions;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import "../vue-qap.less";

@CUSTOMIZED-BY-TEXT-COLOR: @WKCE-ORANGE-SHADE1;

.utd-qap-v {
  .qap-link {
    text-decoration: none;
    display: inline;
  }

  .qap-header {
    .ds1-ph-2();
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
    color: @DS1-UTD-HEADER-TEXT-COLOR;
  }
}

.qap-pathway-banner {
  background-image: url(~_acaAssets/graphic/generic-pathway.png);
}

.sectionItemDetail {
  .ds1-utd-size-1();
  color: @WKCE-ORANGE-SHADE3;
  display: inline;
}

.qap-pathway .qap-title::before {
  .ds1-utd-tree-graph(16px, 16px);
  .ds1-mr-1();
  display: inline-block;
  position: relative;
  top: 2px;
}

.qap-pathway {
  .qap-content {
    .ds1-pl-0();

    .qap-section-item {
      .ds1-pl-2();
    }
  }
}
</style>
