<template>
  <div class="partner-oidc-modal">
    <utd-modal ref="utdPartnerOidcModal"
               modal-classes="partnerOidcModal ds1-utd-font"
               :autofocus="enableAutoFocus"
               :close-on-overlay-click="false"
               @utd-modal-closing="close">
      <div class="partner_oidc__container">
        <div class="partner_oidc__overlay-close wkce-icon-filled-close"
             tabindex="0"
             role="button"
             aria-label="close"
             @keypress="$event.which === ENTER_KEY && close()"
             @click="close" />
        <div class="partner_oidc__toolbar">
          <div class="partner-oidc-title ds1-pa-2 ds1-mr-3">
            UpToDate® - digital answers at your fingertips
          </div>
        </div>
        <div class="partner_oidc__overlay-container">
          <div class="partner_oidc__overlay-content ds1-pa-2">
            <p class="ds1-mt-0 ds1-utd-size-3">
              {{ oidcPartnerDetails.modalConfig.welcomeMessage }}
            </p>
            <p class="ds1-mb-0 ds1-utd-size-3">
              {{ oidcPartnerDetails.modalConfig.welcomeMessageRegOrSubscribe }}
            </p>
          </div>
          <div class="partner_oidc_modal_button__container ds1-pa-2 ds1-utd-size-3">
            <utd-button v-if="oidcPartnerModalButton === 'subscribe'"
                        class="partner-modal-button"
                        button-size="large"
                        button-color="orange"
                        @click="subscribe">{{ $t("HEADER.SUBSCRIBE") }} </utd-button>
            <utd-button v-if="oidcPartnerModalButton === 'register'"
                        class="partner-modal-button"
                        button-size="large"
                        button-color="orange"
                        @click="register">{{ $t("LOGIN.REGISTER") }} </utd-button>
            <utd-button class="login-button partner-modal-button"
                        button-size="large"
                        @click="login">{{ $t("LOGIN.LOG_IN") }} </utd-button>
          </div>
        </div>
      </div>
    </utd-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { C_KEYCODES } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModal from '_acaSrc/components/shared/stdlib/UtdModal.vue';

const MINIMUM_MODAL_HEIGHT_PX = 300;

export default {
    components: { UtdButton, UtdModal },
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        ...mapGetters('app', [
            'router',
            'isTabletDevice',
            'isAuthenticatedByIp'
        ]),
        ...mapGetters('login', [ 'loginHref' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('auth', [
            'oidcPartnerDetails',
            'oidcPartnerModalButton',
            'isOidcUser',
            'storeUrl' ]),
        enableAutoFocus() {
            return this.isDesktopView && !this.isTabletDevice;
        }
    },
    watch: {
        isOidcUser(newVal) {
            if (newVal) {
                this.showModal();
            }
        }
    },
    beforeUnmount() {
        this.close();
        clearTimeout(this.domElementsTimeout);
    },
    methods: {
        ...mapActions('login', [ 'setOidcPartnerModalShown' ]),
        showModal() {
            if (!this.oidcPartnerDetails.modalConfig.shown) {
                this.open();
            }
            else {
                return;
            }
        },
        open() {
            this.$refs.utdPartnerOidcModal.open();
            this.initDimensionsWatcher();
            getWindow().addEventListener('resize', this.setModalDimensions, { passive: true });
        },
        close() {
            getWindow().removeEventListener('resize', this.setModalDimensions, { passive: true });
            this.setOidcPartnerModalShown();
            this.$refs.utdPartnerOidcModal.close();
        },
        login() {
            if (this.isAuthenticatedByIp) {
                // Redirects to login page without triggering a refresh
                this.router.go('login');
            }
            else {
                getWindow().location.href = '/login';
            }
        },
        subscribe() {
            getWindow().open(this.storeUrl, 'storefront', 'noopener');
        },
        register() {
            getWindow().location.href = this.loginHref;
        },
        initDimensionsWatcher() {
            this.domElementsTimeout = setTimeout(() => {
                this.modalEl = document.querySelector('.utd-modal-content');
                this.toolbarEl = document.querySelector('.partner_oidc__toolbar');
                this.partnerOidcModalContentEl
                        = document.querySelector('.partner_oidc__overlay-content');
                this.setModalDimensions();
            }, 1000);
        },
        setModalDimensions() {
            // Ensure toolbar is always visible.
            if (!this.partnerOidcModalContentEl) {
                return;
            }
            this.partnerOidcModalContentEl.removeAttribute('style', '');
            this.modalEl.setAttribute('style', '');
            if (getWindow().innerHeight < this.modalEl.scrollHeight) {
                let height = getWindow().innerHeight
                                - (MINIMUM_MODAL_HEIGHT_PX + this.toolbarEl.offsetHeight);
                height = height < 0 ? 0 : height;
                this.partnerOidcModalContentEl.setAttribute('style', `height: ${height}px`);
            }
        }
    }
};

</script>

<style lang="less">

@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) "~_acaAssets/global/variables";

@LOGIN-BUTTON-FACE: @WKCE-GREEN;
@LOGIN-BUTTON-HOVER: @WKCE-GREEN-SHADE2;
@LOGIN-BUTTON-ACTIVE: @WKCE-GREEN-SHADE1;

.partnerOidcModal .utd-modal-content {
  max-width: calc(100vw - (@ds1-spacing-multiplier * 4));
  position: relative;
  overflow: auto;
  width: auto;
}

.partner_oidc__overlay-close {
  .ds1-ma-0();
  position: absolute;
  cursor: pointer;
  right: 16px;
  margin-top: 19px;
  color: @DS1-UTD-PRIMARY-BG-COLOR;
}

.partner-oidc-title {
  color: @DS1-UTD-PRIMARY-BG-COLOR;
}

.partner_oidc__toolbar {
  min-height: 56px;
  background-color: @DS1-MODAL-TOOLBAR-BG-COLOR;
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
}

.partner_oidc__overlay-container {
  display: inline-block;
  max-width: 100%;
  min-width: 100%;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
}

.partner_oidc__overlay-content {
  overflow: auto;
}

.partner_oidc_modal_button__container {
  background: @WK_UTD_GRAY_15;

  .partner-modal-button {
    display: block;
    width: 90%;
    margin: 10px auto;
  }

  .login-button {
    background-color: @LOGIN-BUTTON-FACE;
    border-color: transparent;

    &:hover {
      background-color: @LOGIN-BUTTON-HOVER;
    }

    &:disabled {
      background-color: @LOGIN-BUTTON-FACE;
    }

    &:active {
      background-color: @LOGIN-BUTTON-ACTIVE;
    }
  }
}

@media only screen and  (min-width: 768px) {
  .isDesktop,
  .isTablet {
    .partnerOidcModal .utd-modal-content {
      max-width: 500px;
    }
  }
}

</style>