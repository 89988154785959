<template>
  <div v-if="result"
       class="broad-query-panel">
    <div v-if="result.title"
         role="heading"
         aria-level="2"
         class="broad-query-panel-title">
      KEY POINTS
      <div class="key-points-contributors__container">
        <a class="contributors-link wkce-icon-filled-check-circle"
           href="#"
           @click.prevent="showContributorsInfo()">
          <span>Contributors</span></a>
      </div>
      <span class="broad-query__title-text ds1-mt-1">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="result.markupTitle || result.title" />
        <utd-translate-button @translate-button-click="handleTranslateClick" />
      </span>
    </div>
    <div class="broad-query-panel__container utd-longform-content-styles-v1">
      <utd-tabbed-accordion tab-style="with-divider wk-tabs-default"
                            :tabbed-content="result.tabbedContent"
                            :active-tab-index="activeTabIndex"
                            :open-accordion-at-idx="defaultOpenAccordionIndex"
                            :accordion-variant="'ds1-accordion'"
                            :accordion-class-array="calculateFeedbackOverlap"
                            @itemOpened="accordionItemOpened($event)"
                            @itemClosed="accordionItemClosed($event)"
                            @selectedTab="tabSelected">
        <template #accordion-media-container="slotProps">
          <utd-media-collection v-if="hasGraphics(slotProps.item.graphics)"
                                class="bqp-accordion-media__container"
                                :title="'Graphics'"
                                :media-collection="slotProps.item.graphics"
                                @onGvdInit="setGVDGraphics" />
        </template>
        <template #side-content="slotProps">
          <utd-media-collection v-if="hasGraphics(slotProps.content.sideContent)"
                                class="collection-type-thumbnails responsive-horizontal-thumbnails"
                                :media-collection="slotProps.content.sideContent"
                                @onGvdInit="setGVDGraphics" />
        </template>
      </utd-tabbed-accordion>
    </div>
    <utd-pendo-feedback class="broad-query-feedback bg-transparent"
                        :feedback-data="feedbackData" />
    <utd-modal-dialog ref="keyPointsContributorsRef"
                      :close-on-overlay-click="false"
                      :close-on-escape="false"
                      :modal-classes="'key-points-contributors-modal'"
                      :dialog-size="'medium'">
      <template #header>Editorially Curated Content</template>
      <p class="contributors-text ds1-ma-0 ds1-pa-2">{{ contributorsText }}</p>
      <template #footer>
        <utd-button @click="closeContributorsModal()">Close</utd-button>
      </template>
    </utd-modal-dialog>
  </div>
</template>

<script>
import UtdMediaCollection from '_acaSrc/components/shared/stdlib/UtdMediaCollection.vue';
import UtdTabbedAccordion from '../../../shared/utd/UtdTabbedAccordion.vue';
import UtdPendoFeedback from '_acaSrc/components/shared/utd/UtdPendoFeedback.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdTranslateButton from '_acaSrc/components/shared/stdlib/UtdTranslateButton.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getEventTargetHref, setEventTargetHref } from '_acaSrc/utility/Events';
import { setQueryParamValue, makeRelativeUrl } from '_acaSrc/utility/http';
import { SET_GRAPHIC_VIEWER_ACCORDION_ITEMS } from '_acaSrc/store/graphic.store';
import {
    SET_BQP_EVENTS_DATA,
    SET_LAST_SELECTED_PANEL_TAB_IDX
} from '_acaSrc/store/search.store';
import {
    C_GRAPHICS,
    C_EVENTS,
    C_KEY_POINTS,
    C_SEARCH,
    C_LOCALIZATION
} from '_acaSrc/utility/constants';
import { TopicLinkTypes } from '_acaSrc/utility/contents/topic/topic';
import PubSub from '_acaSrc/utility/PubSub';
import PracticePoint from '_acaSrc/components/localization/PracticePoint.vue';

const inlineLinksSelector = '.accordion-text__container a';
const inlineGfxSelector = '.accordion-text__container a.graphic';

export default {
    components: {
        UtdMediaCollection,
        UtdTabbedAccordion,
        UtdPendoFeedback,
        UtdModalDialog,
        UtdButton,
        UtdTranslateButton
    },
    mixins: [ PracticePoint ],
    props: {
        result: Object
    },
    data() {
        return {
            currentTabIndex: 0,
            tabList: [],
            contributorsText: C_KEY_POINTS.CONTRIBUTORS_TEXT
        };
    },
    computed: {
        ...mapGetters('device', [ 'isNotDesktopView' ]),
        ...mapGetters('search', [ 'lastSelectedPanelTabIdx' ]),
        defaultOpenAccordionIndex() {
            return this.isNotDesktopView ? -1 : 0;
        },
        feedbackData() {
            return {
                featureType: 'Key Points Panel',
                featureId: this.result.id,
                featureTitle: this.result.title
            };
        },
        calculateFeedbackOverlap() {
            if (!this.result || !this.result.tabbedContent) {
                return;
            }
            const tabs = this.result.tabbedContent;
            const feedbackOverlapTabs = [];
            tabs.forEach(tab => {
                if (!tab.sideContent || tab.sideContent.length === 0) {
                    feedbackOverlapTabs.push('feedback-overflow');
                }
                else {
                    feedbackOverlapTabs.push('');
                }
            });
            return feedbackOverlapTabs;
        },
        activeTabIndex() {
            if (this.lastSelectedPanelTabIdx !== C_SEARCH.INVALID_TAB_IDX) {
                return this.lastSelectedPanelTabIdx;
            }
            return this.result.initialTabIndex;
        }
    },
    mounted() {
        if (!(this.$el && this.$el.querySelectorAll)) {
            return;
        }

        this.setupInlineGraphicsClickHandlers();
        this.$nextTick(this.setupIcons);
        this.setGVDGraphics();
        new PubSub().subscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, this.setGVDGraphics);
        this.result.tabbedContent.forEach(tab => {
            this.tabList.push(tab.tabLabel);
        });
        this.currentTabIndex = this.result.initialTabIndex;
        this.setupGraphicLinkQPs();
        this.populateBqpEventData();
        this.setupPracticePoints({
            contentId: this.result.id,
            source: C_LOCALIZATION.PP_SOURCE.BQP
        });
    },
    beforeUnmount() {
        if (!(this.$el && this.$el.querySelectorAll)) {
            return;
        }
        this.removeInlineGraphicsClickHandlers();
        new PubSub().unsubscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, this.setGVDGraphics);
    },
    methods: {
        ...mapMutations('graphic', [ SET_GRAPHIC_VIEWER_ACCORDION_ITEMS ]),
        ...mapActions('graphic', [ 'handleUseGraphicLink', 'setGraphicViewerGraphics' ]),
        ...mapActions('search', [ 'logBqpSectionToggleEvent' ]),
        ...mapMutations('search', [
            SET_BQP_EVENTS_DATA,
            SET_LAST_SELECTED_PANEL_TAB_IDX
        ]),
        setupInlineGraphicsClickHandlers() {
            Array.from(this.$el.querySelectorAll(inlineGfxSelector))
                .forEach(el => {
                    el.addEventListener('click', evt => {
                        this.onClickInlineGraphic(evt);
                    });
                });
        },
        removeInlineGraphicsClickHandlers() {
            Array.from(this.$el.querySelectorAll(inlineGfxSelector))
                .forEach(el => {
                    el.removeEventListener('click', this.onClickInlineGraphic);
                });
        },
        async handleTranslateClick() {
            let previousPanelIdx = this.lastSelectedPanelTabIdx;
            if (previousPanelIdx === C_SEARCH.INVALID_TAB_IDX) {
                previousPanelIdx = this.result.initialTabIndex;
            }
            this[SET_LAST_SELECTED_PANEL_TAB_IDX](C_SEARCH.RETRIGGER_TAB_WATCH_IDX);
            await this.$nextTick();
            this[SET_LAST_SELECTED_PANEL_TAB_IDX](previousPanelIdx);
        },
        populateBqpEventData() {
            const { rankIndex, searchRank } = this.result;
            this[SET_BQP_EVENTS_DATA]({
                rankIndex, searchRank
            });
        },
        hasGraphics(graphics) {
            return graphics && graphics.length > 0;
        },
        setGVDGraphics() {
            this.setGraphicViewerGraphics({ graphics: this.result.allGraphics });
            this[SET_GRAPHIC_VIEWER_ACCORDION_ITEMS]([
                C_GRAPHICS.SIDEBAR_ACCORDION_AIT,
                C_GRAPHICS.SIDEBAR_ACCORDION_OG
            ]);
        },
        applyCurrentSearchTermToEventTargetHref(evt) {
            const eventHref = getEventTargetHref(evt);
            if (eventHref) {
                const urlWithSearch = setQueryParamValue(
                    eventHref,
                    'search',
                    encodeURIComponent(this.searchParamsSearchText)
                );
                setEventTargetHref(evt, urlWithSearch);
            }
        },
        async onClickInlineGraphic(evt) {
            this.applyCurrentSearchTermToEventTargetHref(evt);
            const { rankIndex, searchRank } = this.result;
            await this.handleUseGraphicLink({ evt,
                options: {
                    isFromCollection: true,
                    skipSidebarStateReset: true,
                    rankIndex,
                    searchRank
                } });
            this.setGVDGraphics();
        },
        logStateEvent({ label, accordionState }, clickType) {
            const accordionHeader = label;
            const topicId = this.result.id;
            const { rankIndex, searchRank } = this.result;
            const tabName = this.tabList[this.currentTabIndex];

            this.logBqpSectionToggleEvent({
                topicId, clickType, tabName, accordionHeader, accordionState, rankIndex, searchRank
            });
        },
        accordionItemOpened({ label }) {
            this.logStateEvent({ label, accordionState: 'OPEN' }, 'BQP_accordion');
        },
        accordionItemClosed({ label }) {
            this.logStateEvent({ label, accordionState: 'CLOSE' }, 'BQP_accordion');
        },
        tabSelected(tabIndex, event) {
            if (event) {
                this.currentTabIndex = tabIndex;
                this.logStateEvent({}, 'BQP_tab_switch');
                this[SET_LAST_SELECTED_PANEL_TAB_IDX](tabIndex);
            }
        },
        setupIcons() {
            Array.from(this.$el.querySelectorAll(inlineLinksSelector))
                .forEach(link => {
                    if (TopicLinkTypes.isExternal(link)) {
                        link.classList.add('ds1-inline-svg-link', 'external-link');
                    }
                    else if (TopicLinkTypes.isPathway(link)) {
                        link.classList.add('ds1-inline-svg-link', 'related-pathway-link');
                    }
                });
        },
        setupGraphicLinkQPs() {
            const tabPanels = this.$el.querySelectorAll('.wk-tab-inner-content');
            tabPanels.forEach((panel, idx) => {
                const tabName = this.tabList[idx];
                const accordions = panel.querySelectorAll('.wk-accordion-item');
                accordions.forEach(acc => {
                    const accordionTitle = acc.querySelector('.wk-accordion-item-header').innerText;
                    const inlineLinks = acc.querySelectorAll('a.graphic');
                    const graphicSectionLinks
                        = acc.querySelectorAll('a.utd-graphic-link__container');
                    inlineLinks.forEach(graphicLink => {
                        let newUrl = setQueryParamValue(graphicLink.href, 'tabTitle', tabName);
                        newUrl = setQueryParamValue(newUrl, 'accordionTitle', accordionTitle);
                        newUrl = makeRelativeUrl(newUrl);
                        graphicLink.href = newUrl;
                    });
                    graphicSectionLinks.forEach(graphicLink => {
                        let newUrl = setQueryParamValue(graphicLink.href, 'tabTitle', tabName);
                        newUrl = setQueryParamValue(newUrl, 'accordionTitle', accordionTitle);
                        newUrl = makeRelativeUrl(newUrl);
                        graphicLink.href = newUrl;
                    });
                });
            });
        },
        showContributorsInfo() {
            this.$refs.keyPointsContributorsRef.open();
        },
        closeContributorsModal() {
            this.$refs.keyPointsContributorsRef.close();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@DEFAULT-NAV-BUTTON-BG-COLOR: @WKCE-WHITE;
@DEFAULT-NAV-BUTTON-HOVER-BG-COLOR: @WKCE-GRAY-TINT4;
@MOBILE-WEB-NAV-BUTTON-BG-COLOR: @WKCE-GRAY-TINT6;
@MOBILE-WEB-NAV-BUTTON-HOVER-BG-COLOR: @WKCE-GRAY-TINT4;
@DS1-KEY-POINTS-CONTRIBUTORS-ICON-COLOR: @WKCE-GREEN;

.broad-query-panel { // Mobile-first
  .ds1-pb-5();
  width: 100%;
  position: relative;

  .broad-query-panel-title {
    .ds1-utd-size-1();
    .ds1-mh-2();
    .ds1-mt-2();
    display: flex;
    flex-wrap: wrap;
    color: @DS1-UTD-GRAY-TEXT-COLOR;

    .key-points-contributors__container {
      margin-left: auto;

      .contributors-link {
        .ds1-mr-0();
        .ds1-pa-0();
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        &:focus {
          outline: @DS1-UTD-FOCUS-OUTLINE;
        }

        span:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        &::before {
          .ds1-mh-compact();
          color: @DS1-KEY-POINTS-CONTRIBUTORS-ICON-COLOR;
          font-size: 16px;
        }
      }
    }

    .broad-query__title-text {
      .ds1-utd-weight-bold();
      .ds1-utd-size-3();
      color: @DS1-UTD-TOPIC-TEXT-COLOR;
      flex: 0 0 100%;
    }
  }

  .broad-query-panel__container {
    width: 100%;

    .tabbed-accordion {
      width: 100%;

      :deep(.tab-bar) {
        .chevron {
          padding: 0;
          background-color: @MOBILE-WEB-NAV-BUTTON-BG-COLOR;

          &:hover {
            background-color: @MOBILE-WEB-NAV-BUTTON-HOVER-BG-COLOR;
          }
        }

        .wk-tabs {
          border-bottom: none;
          width: 0; // Allows the tab-bar to overflow

          .InternetExplorer & { // IE11 override
            .ds1-pl-0();
          }
        }
      }

      :deep(.wk-tab-inner-content) {
        .ds1-mh-2();
        display: flex;
        flex-direction: column-reverse;
        border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
        padding-top: 15px;

        .no-label__container {
          border: @DS1-UTD-GRAY-SINGLE-BORDER;

          > div {
            .ds1-ma-2();
          }
        }

        .accordion__container {
          width: 100%;

          .wk-accordion-item {
            .ds1-mt-1();

            .wk-accordion-item-header-label {
              .ds1-utd-weight-600();
            }

            .accordion-text__container {
              .ds1-ma-2();

              .external-link::before {
                .ds1-utd-external-link(14px, 14px);
                top: 1px;
                margin-right: 2px;
              }

              .related-pathway-link::before {
                .ds1-utd-tree-graph(16px, 20px);
                top: 0;
              }

              .headingAnchor .h1 {
                .ds1-pt-0();
                .ds1-ma-0();
                border-top: none;
              }
            }
          }
        }

        .side-content__container {
          .ds1-mt-1();

          .wk-media-collection {
            margin-bottom: 12px;

            .collection-thumbnails {
              .ds1-mr-compact();
              gap: 16px;

              .utd-thumbnail__container {
                margin: 0;

                &:hover {
                  text-decoration: underline;
                  transition: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .broad-query-feedback {
    position: absolute;
    z-index: @ZINDEX-FEATURED-RESULT-FEEDBACK;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 768px) { // Everything above 768px
  .broad-query-panel { // Large mobile-web first
    .ds1-pb-1();

    .tabbed-accordion {
      :deep(.utd-tabs__container > .tab-content .wk-tab-inner-content) {
        flex-direction: row;

        .feedback-overflow {
          margin-bottom: 28px;
        }

        .side-content__container {
          .ds1-ml-2();

          .wk-media-collection {
            .ds1-mb-3();

            .collection-thumbnails {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .isDesktop { // Large desktop size
    .broad-query-panel {
      .ds1-pb-0();
      width: 100%;

      .broad-query-panel-title {
        .ds1-ma-0();
        .ds1-ph-2();
        border-bottom: 0;
        font-size: 14px;

        .broad-query__title-text {
          .ds1-utd-weight-600();
          font-size: 14px;
        }
      }

      .broad-query-panel__container {
        .ds1-ph-0();

        .tabbed-accordion {
          :deep(.utd-tabs__container) {
            > .tab-bar .chevron {
              background-color: @DEFAULT-NAV-BUTTON-BG-COLOR;

              &:hover {
                background-color: @DEFAULT-NAV-BUTTON-HOVER-BG-COLOR;
              }
            }

            > .tab-content .wk-tab-inner-content {
              margin-left: 14px;
              margin-right: 15px;
              padding-top: 9px;

              .feedback-overflow {
                .ds1-mb-3();
              }
            }
          }
        }
      }

      .broad-query-feedback {
        position: absolute;
        bottom: -10px;
        right: 0;
        z-index: @ZINDEX-FEATURED-RESULT-FEEDBACK;
      }
    }
  }
}
</style>
