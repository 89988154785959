<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="(searchMessaging && systemAction) || isHL7"
       class="utd-search-suggestions ds1-utd-js2">
    <div tabindex="0"
         class="searchFor ds1-inline-block"
         role="heading"
         aria-level="1"
         v-html="searchResultsFor" />
    <div v-if="userAction && userAction.suggestion"> 
      <div class="userAction ds1-inline-block" tabindex="0">
        <span v-if="isSemantic || isAutocorrect" id="alternate-suggestion">{{ $t('SEARCH.SEARCH_INSTEAD') }}</span>
        <span v-if="isMisspell" id="misspell-suggestion">{{ $t('SEARCH.DID_YOU_MEAN') }}</span>
        <span v-for="(candidate, index) in userAction.suggestion.candidates"
              :key="index"
              class="suggestions"
        ><span v-if="index > 0">, </span><a :href="candidate.url">{{ candidate.value }}</a
        ></span>
      </div>
    </div>
    <div v-for="(systemMessage, i) in systemMessages" :key="i">
      <p v-if="getSystemMessageText(systemMessage)"
         class="system-message"
         :class="systemMessage.severity"
         @click="onSystemMessageClick($event)"
         v-html="getSystemMessageText(systemMessage)" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { C_SEARCH } from '_acaSrc/utility/constants';
import { safeDecodeHtmlUriComponent } from '_acaSrc/utility/http';
import { capitalizeFirstLetter } from '_acaSrc/utility/String';

export default {
    computed: {
        ...mapGetters('search', [ 'searchFor', 'isHL7', 'searchMessaging' ]),
        systemAction() {
            return this.searchMessaging
                && this.searchMessaging.systemAction;
        },
        systemMessages() {
            return this.searchMessaging
                && this.searchMessaging.systemMessages || [];
        },
        userAction() {
            return this.searchMessaging
                && this.searchMessaging.userAction;
        },
        searchForText() {
            return safeDecodeHtmlUriComponent(this.searchFor);
        },
        // eslint-disable-next-line complexity
        searchResultsFor() {
            let tSearchFor = '';
            const translateValues = {
                search: this.searchForText
            };

            if (this.isHL7) {
                tSearchFor = this.$t('SEARCH.HL7_RESULTS_FOR', translateValues);
            }
            else if (this.systemAction.type === C_SEARCH.SUGGESTION_TYPE.MATCHED) {
                tSearchFor = this.processExpansions()
                    || this.$t('SEARCH.SEARCH_RESULTS_FOR', translateValues);
            }
            else if (this.systemAction.type === C_SEARCH.SUGGESTION_TYPE.REPLACEMENT) {
                Object.assign(translateValues, {
                    searchMessageText: this.systemAction.searchText
                });
                tSearchFor = this.$t('SEARCH.SEARCH_RESULTS_FOR_REPLACEMENT', translateValues);
            }
            else if (this.systemAction.type === C_SEARCH.SUGGESTION_TYPE.RELAUNCH) {
                Object.assign(translateValues, {
                    searchMessageText: this.systemAction.searchText
                });
                tSearchFor = this.$t('SEARCH.SEARCH_RESULTS_FOR_RELAUNCH', translateValues);
            }
            else if (this.systemAction.type === C_SEARCH.SUGGESTION_TYPE.AUTOCORRECT) {
                Object.assign(translateValues, {
                    search: this.systemAction.searchText
                });
                tSearchFor = this.processExpansions()
                    || this.$t('SEARCH.SEARCH_RESULTS_FOR', translateValues);
            }

            return tSearchFor;
        },
        isSemantic() {
            return this.userAction
                && this.userAction.suggestion
                && this.userAction.suggestion.type === C_SEARCH.SUGGESTION_TYPE.SEMANTIC;
        },
        isMisspell() {
            return this.userAction
                && this.userAction.suggestion
                && this.userAction.suggestion.type === C_SEARCH.SUGGESTION_TYPE.MISSPELL;
        },
        isAutocorrect() {
            return this.userAction
                && this.userAction.suggestion
                && this.userAction.suggestion.type === C_SEARCH.SUGGESTION_TYPE.AUTOCORRECT;
        }
    },
    methods: {
        ...mapActions('graphic', [
            'handleUseGraphicLink'
        ]),
        processExpansions() {
            let result = '';

            this.systemAction.expansions.forEach(expansion => {
                result += expansion.value
                    ? ` <strong>${expansion.name}</strong> <i>(${expansion.value})</i>`
                    : ` ${expansion.name}`;
            });

            if (result) {
                result = `${this.$t('SEARCH.SHOWING_RESULTS_FOR', { results: result })}`;
            }

            return result;
        },
        getSystemMessageText(systemMessage) {
            if (!systemMessage || !systemMessage.text) {
                return '';
            }

            if (systemMessage.severity === 'WARNING') {
                return `${capitalizeFirstLetter(systemMessage.severity)}: ${systemMessage.text}`;
            }

            if (systemMessage.severity === 'INFO') {
                return systemMessage.text;
            }
            return '';
        },
        onSystemMessageClick(event) {
            if (event.target.tagName === 'A') {
                this.handleUseGraphicLink({
                    evt: event,
                    options: { skipSidebarStateReset: true }
                });
            }
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-search-suggestions {
  font-size: 14px;
  line-height: 21px;
  margin-left: 10px;
  .ds1-pv-2();
  .ds1-pr-2();

  .userAction {
    .ds1-mt-compact();
  }

  .searchFor,
  .userAction,
  .INFO {
    .ds1-pl-compact();
  }

  .system-message {
    .ds1-mb-0();
    .ds1-mt-2();

    &.WARNING {
      .ds1-utd-weight-600();
      .ds1-pa-2();
      display: inline-block;
      background-color: @DS1-UTD-PRIMARY-BG-COLOR;
      border: solid 1px @DS1-UTD-WARN;
      border-left-width: 4px;

      &::before {
        color: @DS1-UTD-WARN;
        .ds1-utd-weight-normal();
        content: '\E80C';
        font-family: 'wk-icons-filled';
        margin-right: 5px;
      }
    }

    &.INFO {
      .graphic_table {
        &::before {
          .ds1-utd-table();
          .ds1-mr-compact();
          display: inline-block;
          position: relative;
          top: 3px;
          left: 1px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .isDesktop & {
      .ds1-pt-2();
      .ds1-pb-2();

      .searchFor,
      .userAction,
      .INFO {
        .ds1-pl-2();
      }
    }
  }
}
</style>
