<template>
  <div class="search-panel-container" :class="{'display-shortcuts': showShortcuts}">
    <div v-if="searchBarBody"
         v-show="!isUtdHeaderNavVisible()"
         id="new-search"
         class="utd-search"
         :class="newSearchClass">
      <div class="new-search-box">
        <utd-content-searchbar />
      </div>
    </div>
    <personalized-shortcuts @show-shortcuts="handleShowShortcuts" />
  </div>
</template>

<script>
import UtdContentSearchbar from '_acaSrc/components/contents/search/SearchBar.vue';
import PersonalizedShortcuts from '_acaSrc/components/homePageRedesign/PersonalizedShortcuts.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'HomeSearchPanel',
    components: {
        PersonalizedShortcuts,
        UtdContentSearchbar
    },
    data() {
        return {
            showShortcuts: false
        };
    },
    computed: {
        ...mapGetters('app', [ 'isOidcSearchLanding', 'isProspectView', 'hasHeader' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('search', [ 'searchBarBody', 'searchBarHeader' ]),
        ...mapGetters('feature', [ 'hideNavBar', 'isHeaderRedesign', 'isHomepageRedesign2024' ]),
        newSearchClass() {
            return {
                hideWidget: this.permissions.myUpToDate
            };
        }
    },
    methods: {
        isUtdHeaderNavVisible() {
            // Method shows when utd header nav bar fit all window size.
            // Used to fix CESUS-1535 issue
            return !this.isHeaderRedesign
          && !this.isProspectView
          && !this.isOidcSearchLanding
          && this.hasHeader
          && !this.searchBarHeader
          && !this.hideNavBar
          && !this.isHomepageRedesign2024;
        },
        handleShowShortcuts(data){
            this.showShortcuts = data;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) "~_acaAssets/global/variables";

@UTD-SEARCH-BAR-DESKTOP-HEIGHT: 54px;

.utdWkHomePage2024 #utd-main {
  .hideWidget {
    .ds1-pb-0();
  }

  .search-panel-container {
    width: 768px;
    margin: clamp(30px, 5vh, 40px) auto 0;

    #new-search {
      margin-bottom: 0;

      :deep(.search-bar-container) {
        padding: unset;
      }

      transition: margin-top 0.3s ease-in-out;

      :deep(.newsearch-submit) {
        transition: background-color 0.1s ease-in;

        &:hover {
          background-color: @WKCE-GRAY-SHADE1;
          opacity: 1;
        }
      }

      @media only screen and (min-width: 768px) {
        .isSmallScreen& {
          padding: 0 32px;
        }
      }

      @media only screen and (max-width: 767px) {
        .isTablet& {
          max-width: unset;
          margin: 49px 0 19px;
        }

        margin-top: 28px;
      }
    }

    @media only screen and (max-width: 832px) {
      max-width: 768px;
      width: min(768px, 92vw);
    }

    @media only screen and (max-width: 767px) {
      .ds1-ma-0();
      width: 100%;
    }

    .isTablet& {
      margin: clamp(30px, 5vh, 40px) auto 0;
      width: auto;

      @media only screen and (min-width: 768px) {
        width: 768px;
        margin: clamp(30px, 5vh, 40px) auto 0;
      }
    }
  }

  .search-panel-container.display-shortcuts #new-search {
      margin-bottom: 15px;
  }
}

.utdWkHomePage2024 .utd-search {
  border-radius: 4px;
  position: relative;

  :deep(.hasfocus .search-bar) {
    left: auto !important;
    right: auto !important;
  }

  &.hideWidget {
    .ds1-pb-0();
  }

  @media only screen and (max-width: 767px) {
    &.hideWidget {
      padding-bottom: unset;
    }
  }

  :deep(.search-bar) {
    width: 100%;
    left: auto;
    right: auto;
    top: -1px;
    position: relative;
    height: unset;

    .isTablet& {
      #tbSearch {
        padding-left: 17px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .isTablet& {
      max-width: 540px;
      width: 100%;
    }

    #tbSearch::placeholder {
      color: @WKCE-GRAY;
      font-weight: 300;

      .isTablet& {
        font-weight: 300;
      }
    }

    @media only screen and (max-width: 767px) {
      height: 40px;

      #tbSearch {
        padding-left: 17px;
      }
    }
  }

  h3 {
    font-weight: 300;
    color: #666;
    margin: 10px 0;
  }

  :deep(.siyol-link) {
    position: absolute;
    top: 45px;
    right: 60px;
    font-size: 0.8em;
    color: @WKCE-GRAY-TINT1;
  }

  :deep(.newsearch-submit) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    font-size: 18px;
    background: #757575;

    &::after {
      left: 9px;
      font-size: 18px;
    }
  }

  .utdWkHeader & {
    display: block;
    background: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 43px;
    margin-bottom: 0;
    width: auto;

    :deep(.search-bar-ac-unit) {
      position: relative;

      .autocomplete {
        top: 52px;
        left: 16px;
        right: 40px;
      }
    }

    h3 {
      display: none;
    }

    :deep(.search-icon) {
      display: none;
    }

    :deep(.search-bar) {
      height: 40px;

      .utdBarSearchCtrlContainer .utdBarSearchCtrl {
        padding-right: 32px;
      }
    }
  }
}

.show-personalization-widget {
  .ds1-utd-js2-link();
  display: none;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (min-aspect-ratio: ~"4/3") {
  .isTablet.utdWkHeader {
    #new-search {
      .show-personalization-widget {
        display: inline-block;
        font-size: 15px;
        margin-top: 1px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .wkProspect .isDesktop {
    &:not(.hasPromo) .utd-search {
      margin-bottom: 300px;
    }
  }

  .isDesktop {
    .hero-logo {
      background: url('~_acaAssets/utd-menu/hero-logo.svg') no-repeat;
      background-position: center;
      height: 64px;
      margin: 4vh auto;
    }

    &.utd-logo-cc .hero-logo {
      background: url('~_acaAssets/utd-menu/hero-logo-ucc.svg') no-repeat;
      background-position: center;
    }

    .utd-search {
      display: block;
      width: 100%;
      margin: auto;

      :deep(.search-bar .utdBarSearchCtrlContainer) {
        .utdBarSearchCtrl {
          .wkProspect & {
            position: relative;
          }
        }
      }

      :deep(.hasfocus .search-bar) {
        left: auto !important;
        right: auto !important;
      }

      .show-personalization-widget {
        display: inline-block;
        font-size: 11px;
        margin-top: 7px;
      }
    }

    .search-bar {
      height: @UTD-SEARCH-BAR-DESKTOP-HEIGHT;
    }
  }

  .utdWkHomePage2024 .utd-search {
    :deep(.search-bar-ac-unit) {
      .autocomplete {
        top: 58px;
        left: 24px;
        right: 58px;

        .isTablet& {
          left: 16px;
          right: 40px;
          top: auto;
        }
      }
    }

    .utdWkHeader & {
      :deep(.search-bar-ac-unit) {
        .autocomplete {
          top: 58px;
          left: 28px;
          right: 58px;
        }
      }
    }
  }
}
</style>