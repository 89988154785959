<template>
  <div class="qap-panel qap-pcu ds1-qap-container">
    <div class="qap-header">
      <div class="qap-title wkce-icon-announce" 
           role="heading"
           aria-level="2">Practice Changing UpDates</div>
    </div>
    <div v-for="(content, contentIdx) in result.qapContents"
         :key="contentIdx"
         class="qap-content ds1-pl-2">
      <section class="qap-section ds1-pv-2"
               :class="{ 'qap-separator-top': contentIdx > 0 }">
        <div v-for="(item, itemIdx) in content.sections[0].items"
             :key="itemIdx"
             class="qap-section-item ds1-pr-2">
          <div class="qap-pcu-month-year">{{ item.modifiers }}</div>
          <a :href="item.url" class="qap-link">{{ item.label }}</a>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="qap-text" v-html="cleanSnippet(item)" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { stripAnchorTags } from '_acaSrc/utility/String';

export default {
    props: {
        result: Object
    },
    methods: {
        cleanSnippet(item) {
            return stripAnchorTags(item.detail);
        }
    }
};
</script>

<style lang="less">
.qap-pcu .qap-section-item .glyph {
  display: none;
}

/* To remove bottom border from containing LIs for new Vue QAPs */
ul.utd-listView > li.QapVue {
  border-bottom: 0;
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import "../vue-qap.less";

.utd-qap-v {
  .qap-link {
    text-decoration: none;
  }
}

.qap-header {
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
  color: @DS1-UTD-HEADER-TEXT-COLOR;
}

.wkce-icon-announce::before {
  .ds1-mr-1();
  font-size: 16px;
  position: relative;
  top: 2px;
}

.qap-pcu-month-year {
  padding-bottom: 4px;
  color: @DS1-UTD-SUB-TEXT-COLOR;
}

/* Need :deep() here to apply to lazy loaded v-html content.
*/
.qap-text :deep(p) {
  .ds1-mt-2;
  .ds1-mb-0;
}

.qap-separator-top {
  border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
}

</style>