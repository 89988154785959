<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-if="result.customizedBy" class="customized-pathway-label">{{ result.customizedBy }}</div>
    <a v-if="!result.nestedResults && result.title"
       :href="getSetSearchUrlWithParams(result, result, true)"
       :aria-label="topicSearchResultAreaLabel"
       :alt="topicSearchResultAlt"
       :target="topicSearchResultTarget"
       class="searchResultLink search-result-primary-link"
       :class="searchResultLinkClasses"
       role="heading"
       aria-level="2"
       v-html="result.title" />
    <span v-if="result.nestedResults"
          :class="result.pageType"
          tabindex="0"
          role="heading"
          aria-level="2"
          :aria-label="nestedResultAreaLabel"
          v-html="result.title" />
    <utd-view-in-language :topic-info="result" />
    <div v-if="showSnippet"
         class="snippet"
         tabindex="0"
         :aria-label="snippedAreaLabel"
         v-html="result.snippet" />
    <div v-if="result.notes"
         class="snippet"
         :aria-label="notesAreaLabel"
         role="note"
         v-html="result.notes" />
    <search-result-subhits :result="result" />
  </div>
</template>

<script>
import SearchResultSubhits from '_acaSrc/components/contents/search/SearchResultSubhits.vue';
import UtdViewInLanguage from '_acaSrc/components/shared/utd/UtdViewInLanguage.vue';
import { getSetSearchUrlWithParams } from '_acaSrc/utility/contents/search/search';
import { mapGetters } from 'vuex';
import { C_SEARCH_RESULTS } from '_acaSrc/utility/constants';

export default {
    components: {
        SearchResultSubhits,
        UtdViewInLanguage
    },
    props: {
        result: Object
    },
    data() {
        return {
            getSetSearchUrlWithParams,
            RESULT_TYPE: C_SEARCH_RESULTS.RESULT_TYPE
        };
    },
    computed: {
        ...mapGetters('search', [ 'isCollapse' ]),
        ...mapGetters('app', [ 'isProspectView', 'isChinaProspectView' ]),
        showSnippet() {
            return this.result.snippet && !this.isChinaProspectView
              && (!this.isCollapse || this.isProspectView);
        },
        topicSearchResultAreaLabel() {
            return `search result topic title ${this.result.title}`;
        },
        nestedResultAreaLabel() {
            return `search result nested topic title ${this.result.title}`;
        },
        snippedAreaLabel() {
            return `${this.result.title} snippet ${this.result.snippet}`;
        },
        notesAreaLabel() {
            return `${this.result.title}: International Brand Name(s) ${this.result.notes}`;
        },
        searchResultLinkClasses() {
            return {
                hasViewInLink: this.result.translatedTopicInfos
                    && this.result.translatedTopicInfos.length,
                icgTitle: this.result.type === this.RESULT_TYPE.ICG,
                labTitle: this.result.subtype === this.RESULT_TYPE.NARRATIVE_LABI,
                visualdx: this.result.type === this.RESULT_TYPE.VISUALDX,
                rxTransitionsTitle: this.result.type === this.RESULT_TYPE.RX_TRANSITIONS,
                'custom-pathway': this.result.customizedBy
            };
        },
        topicSearchResultTarget() {
            return this.result.type === this.RESULT_TYPE.VISUALDX ? '_blank' : undefined;
        },
        topicSearchResultAlt() {
            return this.result.type === this.RESULT_TYPE.VISUALDX ? 'Opens in new window' : '';
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#searchresults {
  .utd-listView li.visualdx .searchResultLink.visualdx::before {
    content: url("~_acaAssets/search/visual-dx-15x12.gif");
    display: inline-block;
    width: 15px;
    height: 12px;
    margin-right: 10px;
    position: relative;
    top: 1.05px;
  }

  .altsearchResults .search-result {
    .snippet::before {
      content: "\201C";
    }

    .snippet::after {
      content: "\201D";
    }
  }

  .view-in-language {
    display: none;
  }
}

ul.utd-listView li .search-result .snippet {
  .ds1-utd-size-1();
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    #searchresults .utd-listView .search-result-primary-link {
      .ds1-utd-h4();
      .ds1-utd-weight-600();
      .ds1-utd-js2-link();
      max-width: 100%;
      flex-basis: 1%; // Non-zero value needed for IE11 support
      flex-grow: 1;
    }

    ul.utd-listView li .search-result .snippet {
      display: block;
      color: @DS1-UTD-GRAY-TEXT-COLOR;
      .ds1-utd-size-2();
      margin-top: 8px;
      padding: 0 3px 0 0;
      flex-basis: 100%;
    }

    #searchresults .view-in-language {
      .ds1-pr-2();
      display: inline-block;
    }
  }
}
</style>
