<template>
  <article class="umc-message-view ">
    <h1 class="umc-message-title">We've moved!</h1>
    <img src="/utd-menu/page-moved.svg" alt="Page moved logo">
    <div class="umc-message-information">
      <div class="umc-marketing-information">
        The Subscriber Manager console has been moved to a new site.
        Admins can sign in directly on the new User Management Console to manage their users.
      </div>
      <div class="umc-marketing-reminder">
        Remember to bookmark the new URL: {{ getUserManagementConsoleLink }}
      </div>
    </div>
    <div class="umc-link">
      <a class="btn-umc"
         :href="getUserManagementConsoleLink">
        Go to new Management Console
      </a>
    </div>
  </article>
</template>

<script>
export default {
    computed: {
        getUserManagementConsoleLink() {
            if (process.env.NODE_ENV === 'development') {
                return 'https://usermanagement-qa.utdlab.com';
            }
            return 'https://usermanagement.uptodate.com';
        }
    }
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.umc-message-view {
  padding: 40px 62px;
  text-align: center;

  .umc-message-title {
    align-items: center;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 400;
    margin: 0;
  }

  img {
    .ds1-mv-5();
    width: 112px;
    height: 112px;
  }
}

.umc-message-information {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.umc-marketing-information {
  .ds1-pb-2();
}

.umc-message-information,
.umc-message-title {
  width: 100%;
  text-align: left;
}

.umc-link {
  .ds1-mt-7();
}

.btn-umc {
  text-decoration: none;
  color: @WKCE-WHITE;
  background-color: @WKCE-BLUE;
  padding: 8px 16px;

  &:hover {
    background-color: @WKCE-BLUE-SHADE1;
  }
}

@media only screen and (min-width: 768px) {
  .umc-message-view {
    padding: 0;
    margin-top: 56px;
    .ds1-mt-7();

    .umc-message-title {
      font-size: 60px;
    }

    img {
      width: 160px;
      height: 160px;
      .ds1-mt-8();
      margin-bottom: 44px;
    }
  }

  .btn-umc {
    padding: 12px 24px;
  }
}
</style>