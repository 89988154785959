<template>
  <utd-modal-dialog ref="modalDialog"
                    :modal-classes="'feedback-error-dialog'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop">
    <template #header>Feedback form error</template>
    <utd-notification class="feedback-alert"
                      :notification-mode="'error'">
      <template #title>Feedback form is not working now.</template>
      <ul class="ds1-pl-3 ds1-utd-size-3">
        <li>
          Please try again later. The form for providing comments is not working now.
        </li>
        <li>
          In addition, the UpToDate Editorial team may be reached directly at:
          <a v-if="isUccState"
             href="mailto:DL-UTD-ChinaSupport@uptodate.com"
             aria-label="Email China Support"
             class="editorial-link">
            DL-UTD-ChinaSupport@uptodate.com
          </a>
          <a v-else 
             href="mailto:editorial@uptodate.com" 
             aria-label="Email Support" 
             class="editorial-link">
            editorial@uptodate.com
          </a>
        </li>
      </ul>
    </utd-notification>
    <div class="feedback-error-account-support">
      <hr class="feedback-error-divider">
      <div class="feedback-error-dialog-text">
        <p class="ds1-utd-h4">
          If you need account support or are having technical issues please let us 
          know how we can help.
        </p>
        <p class="feedback-error-contact-text ds1-utd-size-3">
          Please contact customer support using the UpToDate contact support page:
          <a :href="contactSupportLink()" aria-label="Contact Support">
            <span class="form-link-text">Contact&nbsp;support</span>
            <i class="wk-icon wkce-icon-question-circle" />
          </a>
        </p>
      </div>
    </div>
    <template #footer>
      <utd-button @click="closeModal()"> {{ $t("OK") }} </utd-button>
    </template>
  </utd-modal-dialog>
</template>
  
<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import { USER_CONTENT_FEEDBACK } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UtdNotification
    },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('device', [ 'isMobileOnDesktop' ])
    },
    methods: {
        openModal() {
            this.$refs.modalDialog.open();
        },
        closeModal() {
            this.$refs.modalDialog.close();
        },
        contactSupportLink() {
            return this.isUccState ? USER_CONTENT_FEEDBACK.LINK.CONTACT_SUPPORT_UCC_URL
                : USER_CONTENT_FEEDBACK.LINK.CONTACT_SUPPORT_URL;
        }
    }
};
</script>
  
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.feedback-error-dialog {
    .utd-modal-content .utd-dialog-frame {
        width: 700px;

        .utd-dialog-content {
            .ds1-pa-2();
            .ds1-pb-4();
        }
    }

    .utd-notification {
        .ds1-pb-0();

        .utd-notification-icon {
            .ds1-pt-compact();
        }

        .utd-notification-title {
            .ds1-utd-h3();
        }
    }
}

.utd-dialog-header {
    .ds1-utd-weight-600();
}

@media screen and (max-width: 768px) {

.feedback-error-dialog {
    .utd-modal-content .utd-dialog-frame {
        width: auto;
        max-width: 360px;
    }
  }
}

</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.feedback-alert {
    .ds1-mt-0();
    border: none;
    :deep(.utd-notification-icon) {
      font-size: 24px;
      top: 14px;
    }
}

.feedback-error-account-support {
    .ds1-pl-2();
    .ds1-pr-2();
}

.feedback-error-divider {
    border:1px solid @DS1-UTD-GRAY-BORDER-COLOR;
}

.feedback-error-contact-text {
    .ds1-mb-0();
}

.editorial-link {
  color: @WKCE-BLUE-SHADE3;
}

</style>
  