<template>
  <div v-hover-delay="{
         afterDelay: ()=> null,
         afterUnhoverDelay: onDismis,
         sharedHoverState: 'navMenu',
         msUnhoverDelay: 0
       }"
       class="navigation-menu-container">
    <button id="navbarNavigationMenu"
            ref="menuToggle"
            aria-label="Open Navigation Menu"
            tabindex="0"
            alt="navigation menu"
            class="navigation-menu"
            :class="{ isMenuShowing }"
            @keyup.escape="showMenu()"
            @click="showMenu()">
      <div ref="navigationMenu"
           class="utd-hamburger wkce-icon-menu"  
           @blur="onDismis()" />
    </button>
    <nav v-show="isMenuShowing" class="navigation-menu-nav">
      <ul class="wk-nav">
        <li>
          <span role="menuitem"> {{ $t("CONTENT.TOOLS") }}</span>
        </li>
        <li v-if="showDrugInteractionsApp"
            id="navbarDrugInt">
          <a href="/drug-interactions?source=navbarDrugInt"
             tabindex="0"
             role="menuitem"
             @click="onDismis()"
             v-text="$t('HEADER.DRUG_INTERACTIONS')" />
        </li>
        <li v-if="shouldShowCalculators"
            id="navbarCalculators">
          <a :href="localizeAnchor('/contents/',
                                   'table-of-contents/calculators/alphabetized', language)"
             role="menuitem"
             tabindex="0"
             @click="onDismis()"
             @focus="hideContents"
             v-text="$t('HEADER.CALCULATORS')" />
        </li>
        <li v-if="isShowRxTransitions && !isUccState"
            id="navbarRxTransitions">
          <a href="/rxtransitions?source=navbarRxTransitions"
             role="menuitem"
             @click="onDismis()"
             v-text="$t('HEADER.RX_TRANSITIONS')" />
        </li>
        <li v-show="hasPathwaysAccess && !isUccState"
            id="navbarPathways">
          <a :href="localizeAnchor('/contents/',
                                   'table-of-contents/pathways', language)"
             role="menuitem"
             @click="onDismis()"
             v-text="$t('SEARCH.UPTODATE_PATHWAYS')" />
        </li>
      </ul>
      <ul v-if="!isUccState" class="wk-nav">
        <li>
          <span role="menuitem"> {{ $t("HEADER.CONTENTS") }}</span>
        </li>
        <li v-for="(item, idx) in visibleTocRootItems" :key="idx">
          <a :id="item.navClass"
             :class="item.navClass"
             role="menuitem"
             tabindex="0"
             :href="buildTocUrl(item)"
             :target="item.newTab ? '_blank' : undefined"
             @click="onDismis()">
            {{ $t(item.key) }}
          </a>
        </li>
      </ul>
      <ucc-menu-items v-else class="wk-nav" @onLinkClick="onDismis()" />
    </nav>
  </div>
</template>
<script>
import HoverDelay from '_acaSrc/directives/UtdHoverDelay.directive';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { mapGetters } from 'vuex';
import { C_KEYCODES, C_PATHS } from '_acaSrc/utility/constants';
import { createPositionManager } from '_acaSrc/utility/DOM';
import UccMenuItems from '_acaSrc/components/homePageRedesign/UccMenuItems.vue';

export default {
    components: {
        UccMenuItems
    },
    directives: {
        HoverDelay
    },
    data() {
        return {
            ESCAPE_KEY: C_KEYCODES.ESCAPE_KEYCODE,
            isMenuShowing: false,
            tocPrefix: C_PATHS.TOC_PREFIX
        };
    },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('toc', [ 'tocRootItems' ]),
        ...mapGetters('user', [ 'language', 'userLoggedIn', 'isCustomer' ]),
        ...mapGetters('feature', [
            'showDrugInteractionsApp',
            'hasPathwaysAccess',
            'isShowRxTransitions',
            'topicRedesign'
        ]),
        visibleTocRootItems() {
            return this.tocRootItems.filter(item => {
                return item.display !== false && (!item.featureCheck || (item.featureCheck()));
            }).sort((a, b) => a.redesignPosition >= b.redesignPosition ? 1 : -1);
        },
        shouldShowCalculators() {
            return (this.isCustomer || this.userLoggedIn);
        }
    },
    isMenuShowing() {
        this.$nextTick(() => {
            if (this.isDesktopView && this.positionManager) {
                this.positionManager.update();
            }
        });
    },
    mounted() {
        if (this.isDesktopBrowser) {
            this.positionManager
                = createPositionManager(this.$refs.menuToggle, this.$refs.fontMenu);
        }
        if (this.positionManager && this.isNotDesktopView) {
            this.positionManager.reset();
        }
    },

    methods: {
        showMenu() {
            this.isMenuShowing = !this.isMenuShowing;
        },
        buildTocUrl(item) {
            const contentsPrefix = item.hasContentsPrefix ? '/contents/' : '';

            if (item.notLocalized) {
                return contentsPrefix + item.url;
            }

            const prefixedItemUrl = `${item.hasTocPrefix ? this.tocPrefix : ''}${item.url}`;
            const localizedUrl = this.localizeAnchor(contentsPrefix, prefixedItemUrl, this.language);

            if (item.source) {
                return setQueryParamValue(localizedUrl, 'source', item.source);
            }

            return localizedUrl;
        },
        onDismis(){
            this.isMenuShowing = false;
            this.$refs.menuToggle.blur();
        }
    }
};
</script>
<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-hamburger {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  color: @WKCE-WHITE;

  .bar {
    height: 1px;
    width: 20px;
    background-color: white;

    &:not(.three) {
      margin-bottom: 6px;
    }
  }
}

.navigation-menu {
  .ds1-ml-0();
  border: none;
  position: relative;
  padding: 15px 24px;
  transition: all ease-in-out 0.3s;
  background-color: @WKCE-GRAY-TINT1;

  &:hover,
  &:focus,
  &.isMenuShowing {
    background: @WKCE-GRAY-SHADE1;
  }
}

.navigation-menu-nav {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: @WKCE-WHITE;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  box-shadow: 0 0 4px 0 @DS1-BOX-SHADOW-THUMB-RGBA;
  z-index: @ZINDEX-ACCOUNT-NAV;
  text-align: left;
  max-height: calc(100vh - 60px);
  overflow-y: auto;

  .wk-nav {
    .ds1-mt-0();
    flex-direction: column;
    width: 100%;
    display: flex;

    > li {
      > a,
      > span {
        .ds1-ph-2();
        .ds1-pv-1();
      }

      > span, > span.menu-title {
        display: block;
        color: @WKCE-GRAY-TINT1;
        text-transform: uppercase;
        cursor: default;
      }

      a:hover{
        background-color: @WKCE-BLUE-TINT5;
        color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
      }
    }

    &:not(:last-child)::after {
      width: calc(100% - 32px);
      margin: 4px auto;
      display: block;
      height: 1px;
      background-color: #d9d9d9;
    }
  }
}

@media only screen and (min-width: 768px) {
  .navigation-menu-nav {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: @WKCE-WHITE;
    border: @DS1-UTD-GRAY-SINGLE-BORDER;
    box-shadow: 0 0 4px 0 @DS1-BOX-SHADOW-THUMB-RGBA;
    z-index: @ZINDEX-ACCOUNT-NAV;
    text-align: left;
    display: flex;
    flex-direction: column;

    > .wk-nav {
      .ds1-pr-0();
      
      &:last-child {
        .ds1-pb-1();
      }
    }
  }

  .loggedIn .navigation-menu {
    .ds1-ml-2();
  }

  .navigation-menu {
    .ds1-ml-4();
  }

  .isDesktop.utdWkHomePage2024.utdWkHeader .wk-header .wk-header-container .navigation-menu-container .wk-nav>li:not(.login-btn, .register-btn)>a {
    color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
  } 
}
</style>