<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-if="canViewGraphicTooltips"
         ref="content"
         class="graphicHoverTooltip"
         :class="{ 'multipleGraphics': hasMultipleGraphics}">
      <utd-thumbnail v-for="graphic in hoverTooltipGraphics"
                     :key="graphic.imageKey"
                     :graphic="graphic"
                     large
                     @utd-thumbnail-click="showModal($event, graphic)" />
      <a v-if="showViewAllGraphics"
         class="viewAllGraphics"
         @click="showModal($event, hoverTooltipGraphics[0])">
        <span class="viewAllGraphicsContent">View all {{ imageKeyCount }} graphics</span></a>
    </div>
    <graphic-viewer-dialog v-if="hasGraphicModal" ref="graphicModal" show-counter />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { GraphicHoverTooltip } from '_acaSrc/utility/tooltip';
import {
    SET_GRAPHIC_VIEWER_COLLECTION
} from '_acaSrc/store/graphic.store';
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';
import { setSearchUrlParamsHelper, getQueryParamValues } from '_acaSrc/utility/http';
import { elAddClass, getWindow } from '_acaSrc/utility/DOM';
import { C_GRAPHICS, C_EVENTS, C_AI_SEARCH } from '_acaSrc/utility/constants';
import GraphicViewerDialog from '_acaSrc/components/contents/graphic/GraphicViewerDialog.vue';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';

const UNIQUE_HOVER_CLASS = 'graphic-hover-tooltip-unique';
const RENDERING_TIMEOUT = 250;
const SKIP_GVD_SIDEBAR_RESET_SOURCES = [
    'kpp',
    'kpp-graphic-section',
    'bqp',
    'bqp-graphic-section'
];

export default {
    components: {
        UtdThumbnail,
        GraphicViewerDialog
    },
    props: {
        hoverSelector: {
            type: String,
            default() {
                return '#topicContainer #topicArticle .graphic[class*="graphicRef"]';
            }
        },
        offTopicVariant: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            hoverTooltipGraphics: [],
            imageKeyCount: 0,
            hoverCount: 0,
            imageSource: '',
            hasInitialized: false,
            keyboardListener: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'tooltipConfig',
            'router',
            'isPrintView'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('topic', [
            'topicType',
            'isTopicView'
        ]),
        ...mapGetters('search', [ 'isSearchView' ]),
        ...mapGetters('graphic', [
            'graphicViewerVisible',
            'isBindGfxHoverTooltip',
            'getGraphicByImageKey',
            'shouldRefreshForAiResults'
        ]),
        canViewGraphicTooltips() {
            const matchesTopicType = 'medical|narrative|drug'.indexOf(this.topicType) > -1;
            const isNotPrintView = !this.isPrintView;
            return matchesTopicType && isNotPrintView;
        },
        showViewAllGraphics() {
            return this.imageKeyCount > 2;
        },
        hasMultipleGraphics() {
            return this.imageKeyCount > 1;
        },
        imageKeyWithHoverCount() {
            return `${this.hoverTooltipGraphics[0].imageKey}hover${this.hoverCount}`;
        },
        hasGraphicModal() {
            return !this.offTopicVariant;
        },
        useSourceAnchorText() {
            return this.offTopicVariant
              && !(this.imageSource === 'kpp' || this.imageSource === 'bqp');
        },
        skipGvdSidebarReset() {
            return SKIP_GVD_SIDEBAR_RESET_SOURCES.indexOf(this.imageSource) > -1;
        }
    },
    watch: {
        graphicViewerVisible(newVal) {
            if (newVal) {
                this.$refs.graphicModal && this.$refs.graphicModal.open();
            }
        },
        isBindGfxHoverTooltip() {
            this.reloadTippyHoverState();
        },
        shouldRefreshForAiResults(newVal) {
            newVal && this.reloadTippyHoverState();
        }
    },
    mounted() {
        this.bindHoverTooltip();
    },
    beforeUnmount() {
        this.doUnmount();
    },
    methods: {
        ...mapActions('graphic', [
            'launchGraphicViewerModal',
            'setGraphicViewerCollection',
            'getTooltipGraphic'
        ]),
        ...mapActions('app', [
            'subscribe',
            'unsubscribe',
            'confirmConfigLoaded'
        ]),
        ...mapActions('ai', [
            'shouldDoDeadline'
        ]),
        ...mapMutations('graphic', [
            SET_GRAPHIC_VIEWER_COLLECTION
        ]),
        doUnmount() {
            this.tooltip && this.tooltip.destroy();
            this.unsubscribe({
                eventName: 'SEARCH_RESULT_SHOW_MORE',
                handlerFn: this.reloadTippyHoverState
            });
            this.hasInitialized = false;
            if(this.keyboardListener) {
                removeEventListener(this.keyboardListener);
            }

        },
        async onHoverTrigger(tooltip, hoverEvent) {
            this.hoverCount++;
            this.hoverTooltipGraphics = []; // Empty Array for new hover trigger
            const imageKey = getQueryParamValues(hoverEvent.target.href, 'imageKey');
            const imageKeys = imageKey.split(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR);
            const tooltipGraphicPromises = [];
            this.imageSource = getQueryParamValues(hoverEvent.target.href, 'source');

            this.imageKeyCount = imageKeys.length;
            this.setGraphicViewerCollection({
                event: hoverEvent,
                imageKeys
            });

            if (this.imageKeyCount > 1) {
                tooltipGraphicPromises.push(
                    await this.addTooltipGraphic(hoverEvent, imageKeys[0])
                );
                if (this.imageKeyCount === 2) {
                    tooltipGraphicPromises.push(
                        await this.addTooltipGraphic(hoverEvent, imageKeys[1])
                    );
                }
            }
            else {
                tooltipGraphicPromises.push(
                    await this.addTooltipGraphic(hoverEvent, imageKey)
                );
            }

            Promise.all(tooltipGraphicPromises)
                .then(() => {
                    if (!this.$refs.content) {
                        setTimeout(() => {
                            tooltip.setContent(this.$refs.content);
                        }, RENDERING_TIMEOUT);
                    }
                    else {
                        tooltip.setContent(this.$refs.content);
                    }
                });

            this.addHideOnKeyPress();
        },
        async addTooltipGraphic(event, imageKey) {
            try {
                const graphic = await this.getTooltipGraphic({ event, imageKey });
                if (this.useSourceAnchorText) {
                    graphic.title = graphic.target.innerHTML;
                }
                this.hoverTooltipGraphics.push(graphic);

                return graphic;
            }
            catch (err) {
                Logger.error('Error during getting tooltip graphic', err);
            }
        },
        showModal(event, graphic) {
            new PubSub().publish(C_EVENTS.HOVER_TOOLTIP_CLICK, graphic.target);
            const { rankIndex, searchRank } = graphic;
            const mediaUrl = setSearchUrlParamsHelper(graphic.url, {
                rankIndex, searchRank
            });

            if (this.isDesktopView) {
                event.preventDefault();
                this.launchGraphicViewerModal({
                    imageKey: graphic.imageKey,
                    imageUrl: mediaUrl,
                    isFromCollection: this.hasMultipleGraphics,
                    skipSidebarStateReset: this.skipGvdSidebarReset
                }).then(() => {
                    new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                        targetUrl: graphic.url,
                        uiElementName: 'GraphicHoverTooltip',
                        contentId: graphic.imageKey
                    });
                });
            }
            else {
                this.router.url(graphic.url);
            }
        },
        async bindHoverTooltip() {
            await this.confirmConfigLoaded();
            const bind = () => {
                this.$nextTick(() => {
                    this.resetTippyIfNeeded();
                    this.tooltip = new GraphicHoverTooltip(
                        `.${UNIQUE_HOVER_CLASS}-${this.uuid}`,
                        this.tooltipConfig,
                        this.onHoverTrigger);
                    this.addHoverUniqueClass();
                    this.subscribe({
                        eventName: 'SEARCH_RESULT_SHOW_MORE',
                        handlerFn: this.reloadTippyHoverState
                    });
                });
            };

            /* If we have a deadline, the content isn't presented until after the deadline
               passes the latest; we don't want to bind unless content is actually visible */
            if (this.isSearchView &&
                !this.isTopicView &&
                await this.shouldDoDeadline()) {
                setTimeout(() => {
                    bind();
                }, C_AI_SEARCH.DEADLINE_MAX_MS);
                return;
            }

            bind();
        },
        addHoverUniqueClass() {
            const elems = Array.from(document.querySelectorAll(this.hoverSelector));
            elems.forEach(el => elAddClass(el, `${UNIQUE_HOVER_CLASS}-${this.uuid}`));
            this.tooltip.initialize()
                .then(() => {
                    this.hasInitialized = true;
                })
                .catch(e => {
                    if (e.indexOf('IGNORE_ERROR') === -1) {
                        Logger.warn(`Error initializing graphic hover tooltip: ${e}`);
                    }
                });
        },
        addHideOnKeyPress() {
            this.keyboardListener = getWindow().addEventListener('keyup', this.checkForScapePress);
        },
        checkForScapePress(e) {
            if (e.key === 'Escape' && this.tooltip) {
                console.log(this.tooltip);
                this.tooltip._singleton.hide();
            }
        },
        resetTippyIfNeeded() {
            if (!this.hasInitialized) {
                return;
            }
            this.doUnmount();
        },
        reloadTippyHoverState() {
            this.resetTippyIfNeeded();
            this.bindHoverTooltip();
        }
    }
};
</script>
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-TOOLTIP-ARROW-BORDER-COLOR: rgba(0,8,16,0.2);

.tippy-box[data-theme~='utd-tooltip-interactive-graphic-hover'] {
  .ds1-pa-2();

  .tippy-content {
    padding: 0;
  }

  &[data-placement^=top] {
    & > .tippy-arrow {
      bottom: -13px;

      &::before,
      &::after {
        left: -4px;
      }

      &::before {
        top: 4px;
        bottom: auto;
        border-top-color: @UTD-TOOLTIP-ARROW-BORDER-COLOR;
      }

      &::after {
        top: 3px;
      }
    }
  }

  &[data-placement^=right] {
    & > .tippy-arrow {
      left: -4px;

      &::before {
        border-right-color: @UTD-TOOLTIP-ARROW-BORDER-COLOR;
      }

      &::after {
        right: 11px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphicHoverTooltip {
  display: none;
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .graphicHoverTooltip {
      display: block;

      :deep(.utd-thumbnail__container) {
        margin: 0 20px;

        .utd-thumbnail__label-anchor {
          .ds1-ph-1();
          height: auto;
        }
      }

      .viewAllGraphics {
        border: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;
        background-color: @DS1-UTD-GRAY-BG-COLOR;
        display: inline-block;
        margin-left: 14px;
        vertical-align: top;

        .viewAllGraphicsContent {
          width: 145px;
          height: 145px;
          display: flex;
          flex-flow: column;
          align-items: center;    /* Vertical align */
          justify-content: center;    /* horizontal align */
          &::before {
            .ds1-utd-gallery(40px, 40px);
            .ds1-mb-2();
            display: block;
          }
        }
      }
    }

    .graphicHoverTooltip.multipleGraphics {
      :deep(.utd-thumbnail__container) {
        margin: 0;
      }

      :deep(.utd-thumbnail__container + .utd-thumbnail__container) {
        margin-left: 12px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .isDesktop {
    .graphicHoverTooltip {
      .viewAllGraphics {
        display: inline-flex;
        width: 250px;
        height: 225px;

        .viewAllGraphicsContent {
          flex-grow: 1;
          height: auto;
        }
      }
    }
  }
}
</style>
