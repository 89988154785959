import { C_AI_SEARCH } from '_acaSrc/utility/constants';
import Logger from '_acaSrc/utility/Logger';

const filterOutOldCacheRecords = (cachedRecords) => {
    return cachedRecords.filter(result => {
        return result.timestamp && Date.now() - result.timestamp <
                C_AI_SEARCH.AI_CACHE.AI_CACHE_ITEM_EXPIRATION_MS;
    });
};

const getCachedRecords = (curCache) => {
    let cachedRecords = [];

    if (curCache) {
        try {
            cachedRecords = JSON.parse(curCache);
        }
        catch(e) {
            Logger.error(e);
            return [];
        }

        if (cachedRecords && Array.isArray(cachedRecords) && cachedRecords.length > 0) {
            return cachedRecords;
        }
    }

    return [];
};

const addRecordToCache = (cacheName, cachedRecords, query, results) => {
    cachedRecords.push({
        query,
        results,
        timestamp: Date.now()
    });

    try {
        localStorage.setItem(cacheName, JSON.stringify(cachedRecords));
    }
    catch(e) {
        Logger.info(`Unable to add item to AI cache due to error: ${e}`);
    }
};

/** Returns AI results as-if returned by backend if found in cache */
export const checkForCachedResult = (params) => {
    let cachedRecords = getCachedRecords(
        localStorage?.getItem && localStorage.getItem(C_AI_SEARCH.AI_CACHE.CACHE_NAME)
    );

    if (!params?.query || cachedRecords.length === 0) {
        return;
    }

    cachedRecords = filterOutOldCacheRecords(cachedRecords);

    for (const cacheRecord of cachedRecords) {
        if (cacheRecord.query === params?.query) {
            return Promise.resolve({
                error: '',
                isFromCache: true,
                searchResults: cacheRecord.results
            });
        }
    }
};

export const updateAiCache = (query, results, isFromCache) => {
    if (!query || !results || isFromCache) {
        return;
    }

    const cacheName = C_AI_SEARCH.AI_CACHE.CACHE_NAME;
    const curCache = localStorage?.getItem && localStorage.getItem(cacheName);
    let cachedRecords = getCachedRecords(curCache);

    if (cachedRecords.length > 0) {
        cachedRecords = filterOutOldCacheRecords(cachedRecords);
        const cachedRecordsSizeBytes = (cacheName.length + curCache.length) * 2;
        const maxAiCacheSizeBytes = C_AI_SEARCH.AI_CACHE.AI_CACHE_MAX_BYTES;

        if (cachedRecordsSizeBytes < maxAiCacheSizeBytes) {
            addRecordToCache(cacheName, cachedRecords, query, results);
        }
    }
    else {
        addRecordToCache(cacheName, [], query, results);
    }

};
