import { C_QAP } from '_acaSrc/utility/constants';
import { setQueryParamValue } from '_acaSrc/utility/http';

const UTD_URL_DISPLAY_RANK = 'display_rank';

export function setDesktopUrlDisplayRank(result) {
    if (result.panelType === C_QAP.PANEL.PCU) {
        // For PCU QAP on desktop, all display_rank values are set to 1
        result.qapContents.forEach(content =>
            content.sections.forEach(section =>
                section.items.forEach(item =>
                    item.url = setQueryParamValue(item.url, UTD_URL_DISPLAY_RANK, '1'))));
    }
}