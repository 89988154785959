<template>
  <div class="qap-panel qap-lab-i ds1-qap-container wk-js2">
    <div class="qap-header">
      <div class="qap-title" role="heading" aria-level="2">Lab Interpretation</div>
      <div class="qap-subtitle">Guidance for initial evaluation of an abnormal test result</div>
    </div>
    <div class="qap-content" :class="{singleLabI: !hasMultipleLabI}">
      <utd-accordion :opened-index="0"
                     :accordion-items="accordionItemsData"
                     :can-close-open-accordion="hasMultipleLabI"
                     :is-mutex-accordion="!hasMultipleLabI"
                     :disable-arrow-keys="true"
                     :accordion-style-class="'ds1-accordion'"
                     @itemOpened="accordionItemOpened($event)"
                     @itemClosed="accordionItemClosed($event)">
        <template v-slot:content="slotProps">
          <a class="qap-link" :href="slotProps.item.url">Go to Lab Interpretation details</a>
          <div class="labi-thumbnail-container">
            <a :href="slotProps.item.imageHref"
               class="qap-link labi-thumbnail-anchor"
               @click="onClickGraphicsLink($event)">
              <img v-utd-canvas-scaler
                   class="labi-thumbnail"
                   :src="slotProps.item.thumbnailUrl"
                   width="284">
              <span class="wkce-icon-expand-angle labi-full-size-link">View full size algorithm</span>
            </a>
          </div>
        </template>
      </utd-accordion>
    </div>
  </div>
</template>

<script>
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';
import UtdCanvasScaler from '_acaSrc/components/contents/graphic/CanvasScaler.directive';
import { mapActions, mapGetters } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';

export default {
    directives: {
        UtdCanvasScaler
    },
    components: {
        UtdAccordion
    },
    props: {
        result: Object
    },
    data() {
        return {
            accordionItemsData: this.processAccordionItems()
        };
    },
    computed: {
        ...mapGetters('search', [ 'isGraphicSearch' ]),
        qapSections() {
            return this.result
                && this.result.qapContents
                && this.result.qapContents.length
                && this.result.qapContents[0].sections
                    || [];
        },
        hasMultipleLabI() {
            return this.accordionItemsData.length > 1;
        }
    },
    methods: {
        ...mapActions('graphic', [ 'handleUseGraphicLink' ]),
        accordionItemOpened(event) {
            const { topicId } = event;
            this.logAccordionStateEvent({ topicId, state: 'expand' });
        },
        accordionItemClosed(event) {
            const { topicId } = event;
            this.logAccordionStateEvent({ topicId, state: 'collapse' });
        },
        logAccordionStateEvent({ topicId, state }) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'toggleKP',
                optData: state,
                contentId: topicId
            });
        },
        processAccordionItems() {
            if (!(this.result
               && this.result.qapContents
               && Array.isArray(this.result.qapContents))) {
                return [];
            }
            return this.result.qapContents[0].sections.map(section => {
                const { id, url, imgsrc, detail } = section.items[0];
                return {
                    topicId: id,
                    label: section.title,
                    url,
                    thumbnailUrl: imgsrc,
                    imageHref: detail
                };
            });
        },
        onClickGraphicsLink(evt) {
            this.handleUseGraphicLink({
                evt,
                options: { skipSidebarStateReset: this.isGraphicsSearch }
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import "../vue-qap.less";

.utd-qap-v .qap-header {
  .ds1-ph-2();
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
  color: @DS1-UTD-HEADER-TEXT-COLOR;
}

.qap-lab-i {
  .singleLabI {
    :deep([class*=wkce-icon-chevron-]) {
      display: none;
    }

    :deep(.wk-accordion) {
      .wk-accordion-item-header,
      .wk-accordion-item-header .wk-accordion-item-header-label {
        cursor: initial;
      }
    }
  }

  .qap-title::before {
    .ds1-utd-beaker(16px, 16px);
    .ds1-mr-1();
    display: inline-block;
    position: relative;
    top: 2px;
  }

  :deep(.wk-accordion .wk-accordion-item) {
    border-left: 0;
    border-right: 0;

    .wk-accordion-item-header {
      background-color: @DS1-UTD-PRIMARY-BG-COLOR;
      color: @DS1-UTD-HEADER-TEXT-COLOR;

      .wk-accordion-item-header-label {
        .ds1-utd-weight-600();
      }

      &.wk-accordion-item-header[aria-expanded="true"] + .wk-accordion-item-element {
        max-height: 100%;
      }
    }

    .wk-accordion-item-element {
      position: relative;
      overflow: hidden;
    }

    &.ds1-accordion {
      margin-top: 0;
    }

    .labi-thumbnail-container {
      .ds1-mh-0();
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .labi-thumbnail-anchor {
      margin-bottom: -39px;
    }

    .labi-thumbnail {
      border: @DS1-UTD-GRAY-SINGLE-BORDER;
    }

    .wkce-icon-expand-angle::before {
      margin-right: 8px;
    }

    .labi-full-size-link {
      display: block;
      position: relative;
      margin: 0 auto;
      padding: 16px 0;
      bottom: 55px;
      background-color: @DS1-UTD-PRIMARY-BG-COLOR;
      width: 282px;
      opacity: 0.85;
    }
  }
}

@media only screen and (min-width: 768px) {
  .isTablet .qap-lab-i :deep(.wk-accordion .wk-accordion-item) {
    .labi-thumbnail-anchor {
      margin-bottom: -33px;
    }

    .labi-full-size-link {
      bottom: 49px;
    }

    .labi-thumbnail {
      display: block;
    }
  }
}
</style>