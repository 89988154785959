<template>
  <div class="thumb-controls">
    <a class="thumbs-up" @click="thumbClicked('up')">
      <thumbs-up :is-solid="thumbsState === 'up'" />
    </a>
    <a class="thumbs-down" @click="thumbClicked('down')">
      <thumbs-down :is-solid="thumbsState === 'down'" />
    </a>
    <utd-modal-dialog ref="feedbackModal"
                      :close-on-overlay-click="false"
                      :close-on-escape="false"
                      :modal-classes="'ai-feedback-modal ds1-utd-font'">
      <template #header> UpToDate Feedback </template>
      <iframe class="ai-feedback-survey"
              :src="surveyUrl" />
    </utd-modal-dialog>
  </div>
</template>

<script>
import { C_DOMAINS } from '_acaSrc/utility/constants';
import { collectionToQueryParams } from '_acaSrc/utility/http';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import ThumbsUp from '_acaSrc/components/shared/svg/ThumbsUp.vue';
import ThumbsDown from '_acaSrc/components/shared/svg/ThumbsDown.vue';

export default {
    components: {
        UtdModalDialog,
        ThumbsUp,
        ThumbsDown
    },
    props: {
        surveyPath: {
            type: String,
            required: true
        },
        surveyMeta: {
            type: Object
        }
    },
    data() {
        return {
            thumbsState: null
        };
    },
    computed: {
        surveyUrl() {
            let curUrl = `${C_DOMAINS.QUALTRICS_CDN}${this.surveyPath}?`;

            curUrl += collectionToQueryParams({ 
                thumbs: this.thumbsState,
                ...this.surveyMeta
            });
            return curUrl;
        }
    },
    methods: {
        thumbClicked(state) {
            this.thumbsState = state;
            this.$refs.feedbackModal.open();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.thumb-controls {
  display: flex;

  > a {
    display: flex;
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
    cursor: pointer;

    :deep(svg > path) {
      stroke: @WKCE-GREEN-SHADE1;
    }

    &:hover :deep(svg > path),
    :deep(svg.solid-fill > path) {
      color: @WKCE-GREEN-SHADE1;
    }
    
    &.thumbs-up {
      margin-right: 4px;
    }
    &.thumbs-down {
      margin-left: 4px;
    }
  }
}

@media only screen and (min-width: 768px) { 
  .isDesktop .thumb-controls > a {
    height: unset;
    width: unset;
  }
}
</style>

<style lang="less"> // Unscoped due to nature of utd-modal
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.ai-feedback-modal {
  .ds1-pa-2();

  .utd-dialog-header {
    background-color: @WKCE-WHITE;
    color: @WKCE-GRAY-SHADE1;
    font-weight: 400;
    font-size: 20px;
    padding: 10px 16px;
    border-bottom: 1px solid @WKCE-GRAY-TINT4;
    height: 27px;
    line-height: unset;

    .wkce-icon-filled-close::before {
      color: @WKCE-GRAY-SHADE1;
    }
  }

  .utd-modal-overlay__container {
    height: 100%;
    width: 100%;
    max-height: 800px;

    .utd-modal-content {
      width: 100%;
      max-width: 700px;
    }
  }

  .utd-dialog-frame {
    display: flex;
    height: 100%;

    .utd-dialog-content {
      display: flex;
      max-height: none;
      height: 100%;

      .ai-feedback-survey {
        width: 100%;
        border: none;
      }
    }

    .utd-dialog-footer {
      display: none;
    }
  }
}
</style>