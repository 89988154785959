<template>
  <div class="item" :class="itemClass">
    <a class="item-link" :href="url" :aria-label="$t(langKey)">
      <div v-if="leftIcon" class="left-icon" aria-hidden="true">
        <span aria-hidden="true" :class="leftIcon" class="item-icon" />
      </div>
      <div class="item-details">
        <span>{{ $t(langKey) }}</span>
        <span v-if="withAppendedArrow" class="wk-icon-arrow-right" aria-hidden="true" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
    name: 'AwarenessPanelItem',
    props: {
        itemClass: {
            required: false,
            type: String,
            default: ''
        },
        langKey: {
            required: true,
            type: String,
            default: ''
        },
        leftIcon: {
            required: false,
            type: String,
            default: ''
        },
        url: {
            required: true,
            type: String,
            default: ''
        },
        withAppendedArrow: {
            required: false,
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@TRANSITION-TIME: 0.25s;

* {
  box-sizing: border-box;
}

 .item {
  .ds1-utd-font();
  .ds1-utd-weight-500();
  margin: 0 auto;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  transition: font-weight 0s linear 0s,
    box-shadow @TRANSITION-TIME linear @TRANSITION-TIME,
    color @TRANSITION-TIME linear @TRANSITION-TIME;

    &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(166, 209, 234, 0.6) 0.62%, @WKCE-BLUE-TINT5 100%);
    opacity: 0;
    transition: opacity @TRANSITION-TIME linear @TRANSITION-TIME;
    z-index: -1;
  }

  &:not(:last-child) {
    box-shadow: 0 1px 0 0 @WKCE-GRAY-TINT3;
  }

  .item-link {
    transition-delay: 0.25s;
    .ds1-pv-1();
    align-items: center;
    line-height: 20px;
    display: inline-flex;
    width: 310px;

    @media only screen and (max-width: 768px) {
      width: 366px;
    }

    @media only screen and (max-width: 375px) {
      width: 100%;
    }

    .isTablet {
      width: 310px;
    }

    .left-icon {
      width: 70px;
      height: 56px;
      display: flex;

      & > .item-icon {
        min-width: 70px;
        max-width: 70px;
        align-content: center;

        &.drug-interactions {
          background: url('~_acaAssets/utd-redesign/desktop-drug-interactions.svg')
          no-repeat center;
        }

        &.practice-changing-updates {
          background: url('~_acaAssets/utd-redesign/desktop-practice-changing-updates.svg')
          no-repeat center;
        }

        &.whats-new-by-specialty {
          background: url('~_acaAssets/utd-redesign/desktop-whats-new-by-specialty.svg')
          no-repeat center;
        }

        &.patient-education {
          background: url('~_acaAssets/utd-redesign/desktop-patient-education.svg')
          no-repeat center;
        }

        &.calculators {
          background: url('~_acaAssets/utd-redesign/desktop-calculators.svg')
          no-repeat center;
        }

        &.rx-transitions-for-mental-health {
          background: url('~_acaAssets/utd-redesign/desktop-rx-transitions-for-mental-health.svg')
          no-repeat center;
        }

        @media only screen and (max-width: 768px) {
          &.drug-interactions {
            background: url('~_acaAssets/utd-redesign/mobile-drug-interactions.svg')
            no-repeat center;
          }

          &.practice-changing-updates {
            background: url('~_acaAssets/utd-redesign/mobile-practice-changing-updates.svg')
            no-repeat center;
          }

          &.whats-new-by-specialty {
            background: url('~_acaAssets/utd-redesign/mobile-whats-new-by-specialty.svg')
            no-repeat center;
          }

          &.patient-education {
            background: url('~_acaAssets/utd-redesign/mobile-patient-education.svg')
            no-repeat center;
          }

          &.calculators {
            background: url('~_acaAssets/utd-redesign/mobile-calculators.svg')
            no-repeat center;
          }

          &.rx-transitions-for-mental-health {
            background: url('~_acaAssets/utd-redesign/mobile-rx-transitions-for-mental-health.svg')
            no-repeat center;
          }
        }
      }
    }

    .item-details {
      .ds1-pl-1();
      display: inline-flex;
      width: 232px;

      .wk-icon-arrow-right {
        .ds1-pl-1();
        .ds1-mh-0();
        padding-top: 3px;
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      text-decoration: none;
      color: @WKCE-BLUE-SHADE1;
    }

    &:focus-visible {
      outline: 2px solid @WKCE-BLACK;
    }
  }

  &:hover {
    .ds1-utd-weight-600();
    cursor: pointer;
    box-shadow: 0 3px 0 0 @WKCE-BLUE-TINT1;
    box-sizing: border-box;

    &:before {
      opacity: 1;
    }
  }

  .mobileonDesktop & {
    &:hover {
      background: linear-gradient(90deg, rgba(166, 209, 234, 0.6) 0.62%, @WKCE-BLUE-TINT5 100%);
    }
  }

  @media only screen and (max-width: 375px) {
    margin: 0;
  }

  .zh-Hans &,
  .zh-Hant & {
    .item-link {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .isSmallScreen &{
    margin: unset;
  }
}
</style>