import {
    C_LANGUAGES_NAMES,
    C_LANGUAGES_LIST,
    USER_CONTENT_FEEDBACK
} from '_acaSrc/utility/constants';
import i18next from 'i18next';
import PubSub from '_acaSrc/utility/PubSub';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';
import IndexManager from '_acaSrc/utility/IndexManager';
import { getWindow } from '_acaSrc/utility/DOM';
import Logger from '_acaSrc/utility/Logger';
import { safeTimeout } from '_acaSrc/utility/timers';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { getNearestSectionName } from '_acaSrc/utility/DOM';

const LOCATION_RELOAD_TIMEOUT = 200;

export const makeUser = () => {
    return {
        userInfo: {
            firstName: '',
            lastName: '',
            language: 'en'
        },
        supportTag: '',
        loggedIn: false
    };
};

const state = {
    localization: {
        language: C_LANGUAGES_NAMES.EN.CODE,
        languageName: '',
        dialogLanguage: ''
    },
    user: makeUser(),
    isCustomer: false,
    isExpired: false,
    qualtricsURL: ''
};

export const getters = {
    language: state => state.localization.language,
    languageName: state => state.localization.languageName,
    localization: state => state.localization,
    isLanguageSupported: () => (code, forMarketing) => {
        let isSupported = false;
        const supportedLanguages = C_LANGUAGES_LIST;
        for (const lang in supportedLanguages) {
            if ((supportedLanguages[lang].value === code)
                && ((typeof forMarketing === 'undefined')
                 || (typeof forMarketing !== 'undefined'
                         && forMarketing
                         && supportedLanguages[lang].marketing))) {
                isSupported = true;
                break;
            }
        }
        return isSupported;
    },
    userFirstName: state => state.user.userInfo.firstName,
    userLastName: state => state.user.userInfo.lastName,
    userLoggedIn: state => state.user.loggedIn,
    userInfoLanguage: state => state.user.userInfo.language,
    isCustomer: state => state.isCustomer,
    isExpired: state => state.isExpired,
    feedbackContentType: (state, getters, rootState, rootGetters) => {
        if (rootGetters['graphic/isGraphicView'] ||
            rootGetters['graphic/graphicViewerVisible']) {
            return USER_CONTENT_FEEDBACK.TYPE.GRAPHIC;
        }
        else if (rootGetters['topic/isTopicCalculator']) {
            return USER_CONTENT_FEEDBACK.TYPE.CALCULATOR;
        }
        else if (rootGetters['topic/isNarrativeLabI']) {
            return USER_CONTENT_FEEDBACK.TYPE.LABI;
        }
        else if (rootGetters['topic/isDrugTopic']) {
            return USER_CONTENT_FEEDBACK.TYPE.DRUG;
        }
        else {
            return USER_CONTENT_FEEDBACK.TYPE.TOPIC;
        }
    },
    getSectionName: (state, getters, rootState, rootGetters) => {
        if (rootGetters['graphic/isGraphic']) {
            return;
        }
        const nearestHeadingId = rootGetters['topic/nearestOutlineHeadingId'] || 'xhash_H1';
        return getNearestSectionName(nearestHeadingId) || '';
    },

    getDeviceType: (state, getters, rootState, rootGetters) => {
        if (rootGetters['app/isTabletDevice'] ||
            rootGetters['device/isBrowserTypeSmallScreen'] ||
            rootGetters['device/isMobileOnDesktop']) {
            return 'mobileWeb';
        } 
        else {
            return 'desktop';
        }
    },
    getContentSpecialtyId: (state, getters, rootState, rootGetters) => {
        if (rootGetters['graphic/isGraphic']) {
            return;
        }
        return rootGetters['topic/topicSpecialtyId'];
    },
    getQualtricsData: (state, getters, rootState, rootGetters) => {
        const paramsData = {
            contentId: rootGetters['graphic/isGraphic'] ?
                rootGetters['graphic/graphicId'] : rootGetters['topic/topicId'],
            contentVersion: rootGetters['graphic/isGraphic'] ?
                rootGetters['graphic/graphicVersion'] :
                rootGetters['topic/topicVersion'],
            contentTitle: rootGetters['graphic/isGraphic'] ?
                rootGetters['graphic/graphicTitle'] : rootGetters['topic/topicTitle'],
            contentHeading: getters.getSectionName || '',
            contentLanguage: rootGetters['topic/topicLanguage'],
            contentType: getters.feedbackContentType,
            contentSpecialtyId: getters.getContentSpecialtyId || '',
            deviceType: getters.getDeviceType
        };

        return paramsData;
    },
    getQualtricsURL: state => state.qualtricsURL
};

export const SET_LANGUAGE_NAME = 'SET_LANGUAGE_NAME';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_USER_INFO_NAME = 'SET_USER_INFO_NAME';
export const SET_USER_INFO_LANGUAGE = 'SET_USER_INFO_LANGUAGE';
export const SET_DIALOGUE_LANGUAGE = 'SET_DIALOGUE_LANGUAGE';
export const UPDATE_LOCALIZATION_LANGUAGE = 'UPDATE_LOCALIZATION_LANGUAGE';
export const RESET_USER_INFO = 'RESET_USER_INFO';
export const SET_IS_CUSTOMER = 'SET_IS_CUSTOMER';
export const SET_EXPIRATION_STATUS = 'SET_EXPIRATION_STATUS';
export const SET_QUALTRICS_URL = 'SET_QUALTRICS_URL';

export const mutations = {
    [SET_LANGUAGE_NAME]: state => {
        const supportedLanguages = C_LANGUAGES_LIST;
        for (const lang in supportedLanguages) {
            if (supportedLanguages[lang].value === state.localization.language) {
                state.localization.languageName
                    = i18next.t(supportedLanguages[lang].name);
                break;
            }
        }
    },
    [UPDATE_LOCALIZATION_LANGUAGE]: (state, lang) => {
        state.localization.language = lang;
        new IndexManager().bodyCss.set('language', state.localization.language);
    },
    [SET_DIALOGUE_LANGUAGE]: (state, lang) => state.localization.dialogLanguage = lang,
    [SET_USER_INFO]: (state, userInfo) => state.user.userInfo = userInfo,
    [SET_USER_INFO_LANGUAGE]: (state, value) => state.user.userInfo.language = value,
    [SET_USER_INFO_NAME]: (state, value) => {
        state.user.userInfo.firstName = value.firstName;
        state.user.userInfo.lastName = value.lastName;
    },
    [SET_LOGGED_IN]: (state, value) => state.user.loggedIn = value,
    [RESET_USER_INFO]: state => state.user = makeUser(),
    [SET_IS_CUSTOMER]: (state, customer) => state.isCustomer = customer,
    [SET_EXPIRATION_STATUS]: (state, data) => {
        if ('isExpired' in data) {
            state.isExpired = data.isExpired;
        }
    },
    [SET_QUALTRICS_URL]: (state, data) => {
        state.qualtricsURL = data?.signedUrl || '';
    }
};

export const actions = {
    updateLanguage: ({ rootGetters, commit }, lang) => {
        return rootGetters['app/router'].use(lang).then(() => {
            commit(UPDATE_LOCALIZATION_LANGUAGE, lang);
            commit(SET_LANGUAGE_NAME);
            // Notify i18next of the language change
            i18next.changeLanguage(lang);
            new PubSub().publish('wkutd.utd-tabs-update-label-dimensions');
        }).catch(err => {
            // Ignore undefined errors (duplicate requests will cause this)
            if (err) {
                console.log(`Error attempting to set language '${lang}': ${err}`);
            }
        });
    },
    changeLanguage: ({ getters, commit, dispatch }, langKey) => {
        let shouldRedirect = false;
        if (langKey) {
            shouldRedirect = true;
            commit(UPDATE_LOCALIZATION_LANGUAGE, langKey);
        }
        else {
            commit(UPDATE_LOCALIZATION_LANGUAGE, getters.localization.dialogLanguage);
        }
        new ServerMessaging().resetModal();
        dispatch('changeLang', { langKey: getters.language, shouldRedirect });
        new PubSub().publish('wkutd.purgePersistentCache');
    },
    clearUser: ({ commit }) => {
        // Attempt to reset back to default app language
        i18next.changeLanguage();
        commit(SET_LOGGED_IN, false);
    },
    updateUser: ({ getters, commit, dispatch }, data) => {
        commit(RESET_USER_INFO);

        const language = data.language;
        const userInfo = data.userInfo;
        const licensee = data.licensee;

        if (userInfo) {
            commit(SET_LOGGED_IN, !(licensee && data.oidcPartnerName));
            commit(SET_USER_INFO_NAME, userInfo);
        }
        else {
            dispatch('clearUser');
        }
        if (getters.isLanguageSupported(language)) {
            if (getters.userInfoLanguage !== language) {
                commit(SET_USER_INFO_LANGUAGE, language);
            }
            dispatch('updateLanguage', language);
        }
    },
    changeLang: async({ commit, dispatch, rootGetters }, { langKey, shouldRedirect }) => {
        commit(SET_USER_INFO_LANGUAGE, langKey);
        const url = rootGetters['app/router'].url();

        try {
            await utdRest('user/language', langKey);
            await dispatch('updateLanguage', langKey);

            if (shouldRedirect) {
                getWindow().history.back();

                safeTimeout(() => {
                    getWindow().location.reload();
                }, LOCATION_RELOAD_TIMEOUT);
            }
            else if (url.indexOf('myUpToDate=true') > -1) {
                getWindow().location.href = '/contents/search';
            }
            else {
                getWindow().location.reload();
            }
        }
        catch (err) {
            Logger.warn(`UserService: Error during change lang: ${err}`);
        }
    },
    fetchQualtricsURL: async({ getters, commit }) => {
        try {
            const data = await utdRest('feedback/url-signature', getters.getQualtricsData);
            commit(SET_QUALTRICS_URL, data);
        }
        catch (err) {
            Logger.warn(`Feedback: Error retrieving Qualtrics URL: 
                ${JSON.stringify({ name: err.name, message: err.message, ...err}, null, 2)}`);
        }
    }
};

const UserStore = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default UserStore;