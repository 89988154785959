<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="qap-panel qap-drug-info ds1-qap-container wk-js2">
    <utd-modal-dialog ref="utdAlertDialog"
                      :dialog-type="'warn'"
                      :dialog-size="'medium'"
                      modal-classes="utd-alert-dialog">
      <template #header>{{ dialogTitle }}</template>
      <div id="topicContent" class="utdArticleSection">
        <div class="drugH1Div" v-html="dialogContent" />
      </div>
    </utd-modal-dialog>

    <div class="qap-header">
      <div class="qap-title wkce-icon-medicine"
           role="heading"
           aria-level="2">{{ result.title }}</div>
    </div>
    <utd-tabs class="with-divider" :active-tab="activeTab">
      <utd-tab v-for="(content, contentIdx) in result.qapContents"
               :key="contentIdx"
               :title="$t(content.translationKey || content.contentLabel)"
               :tab-id="`${contentIdx}`">
        <lexi-content-dialog :qap-topic-id="content.contentId" />
        <section class="qap-content qap-section">
          <div class="qap-section-container">
            <div class="qap-section-title" v-text="$t('SEARCH.VIEW_FULL_TOPIC')" />
            <a class="qap-link"
               :href="setUrlSource(content.url)"
               v-html="content.title" />
            <p v-if="content.snippet"
               class="ds1-utd-size-1"
               v-html="content.snippet" />
          </div>
        </section>
        <section v-for="(section, sectionIdx) in content.sections"
                 :key="sectionIdx"
                 class="qap-content qap-section"
                 :class="{ isAccordionSection: isAccordionSection(section),
                           isAlertSection: isAlertSection(section) }">
          <div class="qap-section-container">
            <dl v-if="isLinksSection(section)" class="qap-list">
              <dt class="qap-section-title"
                  v-html="getSectionTitle(section.title)" />
              <dd v-for="(item, itemIdx) in section.items"
                  :key="itemIdx"
                  class="qap-list-item"
                  :class="{ qapFlexItem: dosageCallout(item.title, content.translationKey || content.contentLabel) }">
                <a class="qap-link"
                   :class="item.class"
                   :href="setUrlSource(item.url)"
                   v-html="item.title" />
                <div class="section-item-callout"
                     v-text="dosageCallout(item.title, content.translationKey || content.contentLabel)" />
                <ul v-if="item.items.length" class="qap-drug-info-dosing-nested">
                  <li v-for="(child, childIdx) in item.items"
                      :key="childIdx"
                      class="qap-drug-info-dosing-nested-item">
                    <a class="qap-link"
                       :class="child.class"
                       :href="setUrlSource(child.url)"
                       v-html="child.title" />
                  </li>
                </ul>
              </dd>
            </dl>
            <utd-accordion v-if="isAccordionSection(section)"
                           :is-mutex-accordion="true"
                           :accordion-items="sectionAccordionData(section)"
                           @itemOpened="(accItem) => onAccordionClicked(accItem, contentIdx)">
              <template v-slot:content="slotProps">
                <div v-html="slotProps.item.detail" />
              </template>
            </utd-accordion>
            <utd-notification v-if="isAlertSection(section)"
                              :notification-mode="'warn'">
              <template v-slot:title>
                <a v-for="(alert, alertIdx) in section.items"
                   :key="alertIdx"
                   :href="setUrlSource(alert.url)"
                   class="qap-alert-notification-anchor"
                   @click.prevent="showAlertDialog(alert)"
                   v-text="alert.label" />
              </template>
            </utd-notification>
          </div>

        </section>
        <qap-drug-interactions v-if="isShowDrugInteractions(content)"
                               :result="result"
                               :source="source" />
        <qap-rx-transitions v-if="isShowRxTransitions(content)"
                            :result="result"
                            :source="source" />
      </utd-tab>
    </utd-tabs>
  </div>
</template>

<script>
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import QapDrugInteractions from './QapDrugInteractions.vue';
import QapRxTransitions from './QapRxTransitions.vue';
import LexiContentDialog from '_acaSrc/components/contents/topic/LexiContentDialog.vue';
import { setQueryParamValue, getQueryParamValues } from '_acaSrc/utility/http';
import { C_QAP } from '_acaSrc/utility/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        UtdTabs,
        UtdTab,
        UtdAccordion,
        UtdNotification,
        UtdModalDialog,
        QapDrugInteractions,
        QapRxTransitions,
        LexiContentDialog
    },
    props: {
        result: Object,
        source: String
    },
    data() {
        return {
            dialogTitle: '',
            dialogContent: '',
            hasLoggedDrugPanelEvent: false,
            activeTab: 0
        };
    },
    computed: {
        ...mapGetters('qap', [ 'qapActiveTab' ])
    },
    mounted() {
        this.activeTab = this.qapActiveTab;
    },
    methods: {
        ...mapActions('search', [ 'logTopicViewDrugPanelEvent' ]),
        onAccordionClicked(accordionItem, activeTabIdx) {
            if (!this.hasLoggedDrugPanelEvent) {
                const drugUrl = this.result.qapContents[activeTabIdx].url;
                const rank = getQueryParamValues(drugUrl, 'display_rank');
                this.logTopicViewDrugPanelEvent({
                    drugAccName: accordionItem.label,
                    drugAccId: this.result.qapContents[activeTabIdx].contentId,
                    rank,
                    drugUrl
                });
                this.hasLoggedDrugPanelEvent = true;
            }
        },
        isShowDrugInteractions(content) {
            return (content.translationKey || content.contentLabel) !== 'QAP_TABS.drug_patient';
        },
        isLinksSection(section) {
            return section.type === C_QAP.SECTION.LINKS;
        },
        isAccordionSection(section) {
            return section.type === C_QAP.SECTION.ACCORDION;
        },
        isAlertSection(section) {
            return section.type === C_QAP.SECTION.ALERT && section.items.length > 0;
        },
        isShowRxTransitions(content) {
            return content.contentLabel === 'QAP_TABS.drug_general' && this.result.rxTransitions;
        },
        setUrlSource(url) {
            return setQueryParamValue(url, 'source', this.source);
        },
        getSectionTitle(title) {
            return title && title.indexOf(': ') > -1
                ? title
                : this.$t(title);
        },
        dosageCallout(dosageLabel, tabLabel) {
            let callout = '';

            if (dosageLabel === 'Pediatric' && tabLabel !== 'QAP_TABS.drug_pediatric') {
                callout = 'See Pediatric tab above for full pediatric topic';
            }
            else if (dosageLabel === 'Adult' && tabLabel === 'QAP_TABS.drug_pediatric') {
                callout = 'See General tab above for full adult topic';
            }

            return callout;
        },
        sectionAccordionData(section) {
            return section.items.map(item => {
                const { label, detail } = item;
                return {
                    label,
                    detail
                };
            });
        },
        showAlertDialog(alert) {
            this.dialogTitle = alert.label;
            this.dialogContent = alert.content;
            this.$refs.utdAlertDialog.open();
        }
    }
};
</script>

<style lang="less">
@import "../vue-qap.less";

.utd-alert-dialog .utd-dialog-content {
  .ds1-pa-2();

  #topicContent {
    margin: 0;
  }
}

ul.utd-listView li .qap-alert-notification-anchor {
  width: auto;
  padding: 0;
}

/* Search for "memantine" to see lexi table links in the qap.
   Also, see CESUS-816 for original implementation. */
.lexi-table-link {
  display: inline;

  &::before {
    .ds1-utd-table();
    .ds1-mr-compact();
    margin-left: 2px;
    display: inline-block;
    position: relative;
    top: 2px;
  }
}

.lexi-table-link-container {
  .ds1-utd-white-space-nowrap();
}

</style>

<style lang="less" scoped>
@import "../vue-qap.less";

@NESTED-DOSING-LINE-COLOR: @WKCE-GRAY-TINT5;
@ALERT-LIGHT-BULLET-COLOR: @WKCE-GRAY-TINT4;

.qap-drug-info {
  .wkce-icon-medicine::before {
    .ds1-mr-1();
    margin-left: -24px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .qap-title {
    .ds1-ml-4();
    text-align: left;
  }

  :deep(.wk-tab-inner-content) {
    .ds1-pt-2();
  }

  .qap-section-container {
    .ds1-pb-2();
    .ds1-ph-2();

    :deep(.lexi-modal-content) {
      display: none;
    }
  }

  .isAccordionSection .qap-section-container {
    .ds1-pa-0();
    border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
  }

  .isAlertSection .qap-section-container {
    .ds1-pa-0();
    border-top: @DS1-UTD-GRAY-SINGLE-BORDER;

    :deep(.utd-notification) {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 48px;
      margin: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;

      .utd-notification-icon {
        top: 12px;
      }
    }
  }

  .section-item-callout {
    .ds1-ml-1();
    color: @DS1-UTD-SUB-TEXT-COLOR;
    line-height: 21px;
  }

  .qap-drug-info-dosing-nested {
    .ds1-mt-1();
    padding-left: 0;
    list-style-type: none;
  }

  .qap-drug-info-dosing-nested-item {
    border-left: 2px solid @NESTED-DOSING-LINE-COLOR;
    padding-left: 16px;
    margin: 0;

    .qap-link {
      .ds1-mv-compact();
    }
  }

  .qapFlexItem {
    display: flex;

    > .qap-link {
      flex-basis: 60px;
    }
  }

  :deep(.wk-accordion) {
    .wk-accordion-item {
      &:last-child {
        border-bottom: 0;
      }

      .wk-accordion-item-element {
        max-height: 254px;
        overflow-x: auto;

        > div > div {
          .ds1-pv-1();
          margin-right: 10px;

          p {
            .ds1-mt-1();
            text-indent: 0 !important;
            text-align: inherit !important;
          }
        }

        .ubnlist,
        .canbnlist,
        .cbnlist,
        .thclist,
        .htclist {
          ul {
            display: inline !important;
            padding: 0;

            li {
              display: inline;
              font-size: inherit;
              margin: 0;
              padding: 0;
            }
          }
        }

        .fbnlist {
          ul {
            display: block;

            li {
              display: list-item;
              margin: 0;
              padding: 1px 0;
            }
          }
        }
      }

      .wk-accordion-item-header .wk-accordion-item-header-label {
        .ds1-utd-weight-600();
      }
    }
  }

  :deep(.utd-notification-title) {
    margin-bottom: 0;
  }

  .qap-alert-notification-anchor {
    .ds1-mr-1();

    &:last-child {
      .ds1-mr-0();

      &:before {
        .ds1-mr-1();
        display: inline-block;
        content: @DS1-BULLET-CHAR;
        color: @ALERT-LIGHT-BULLET-COLOR;
      }
    }

    &:first-child::before {
      content: '';
      display: none;
    }
  }
}

</style>
