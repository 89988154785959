<template>
  <section class="footer" :class="{ 'isUcc': isUccState }">
    <div v-if="!isUccState" class="footer-row">
      <div class="footer-column">
        <ul>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('contactUs')"
               v-text="getFooterTitle('contactUs')" />
          </li>
          <li id="sinfo_footer">
            <a tabindex="0"
               role="link"
               @click="showSupportTag($event)"
               v-text="getFooterTitle('supportTag')" />
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('aboutUs')"
               v-text="getFooterTitle('aboutUs')" />
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('accessOptions')"
               v-text="getFooterTitle('accessOptions')" />
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <ul>
          <li id="language">
            <a :href="languageLinkHref"
               @click="languageLinkClickEvent">Language</a>
          </li>
          <li id="sla_footer">
            <a :href="getFooterUrl('sla')"
               :target="getFooterTarget('sla')"
               :rel="getFooterRel('sla')"
               v-text="getFooterTitle('sla')" />
          </li>

          <li data-autotest="policiesFooter">
            <a v-utd-new-tab="true"
               :href="getFooterUrl('policies')"
               v-text="getFooterTitle('policies')" />
          </li>
          <li v-if="showManageCookiesLink">
            <a id="ot-sdk-btn"
               tabindex="0"
               class="ot-sdk-show-settings">{{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <ul id="fTier2">
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('uptodateNews')"
               v-text="getFooterTitle('uptodateNews')" />
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('trainingCenter')"
               v-text="getFooterTitle('trainingCenter')" />
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('demos')"
               v-text="getFooterTitle('demos')" />
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="footer-row">
      <div class="footer-column">
        <ul>
          <li id="language">
            <a :href="languageLinkHref"
               @click="languageLinkClickEvent">Language</a>
          </li>
          <li id="sla_footer">
            <a :href="getFooterUrl('sla')"
               :target="getFooterTarget('sla')"
               :rel="getFooterRel('sla')"
               v-text="getFooterTitle('sla')" />
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('policies')"
               v-text="getFooterTitle('policies')" />
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <ul>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('contactUs')"
               v-text="getFooterTitle('contactUs')" />
          </li>
          <li v-if="isUccState" id="sinfo_footer">
            <a tabindex="0"
               role="link"
               @click="showSupportTag($event)"
               v-text="getFooterTitle('supportTag')" />
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('aboutUs')"
               v-text="getFooterTitle('aboutUs')" />
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <ul>
          <li v-if="showManageCookiesLink">
            <a id="ot-sdk-btn"
               tabindex="0"
               class="ot-sdk-show-settings">{{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}</a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               :href="getFooterUrl('accessOptions')"
               v-text="getFooterTitle('accessOptions')" />
          </li>
          <li>
            <a id="help"
               v-utd-new-tab="true"
               :href="getFooterUrl('help')"
               v-text="getFooterTitle('help')" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-row">
      <div v-if="!isUccState" class="footer-column support">
        <ul id="fTier3">
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/health">Wolters Kluwer Health
            </a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/why-uptodate">
              UpToDate<sup>&reg;</sup>
            </a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/medi-span">Medi-Span<sup>&reg;</sup>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-column socials">
        <ul v-show="!isUccState && isTier3Visible">
          <li class="social-circle facebook">
            <a id="sfiFacebook"
               v-utd-new-tab="true"
               class="wkce-icon-filled-facebook"
               href="https://www.facebook.com/UpToDateEBM"
               aria-label="UpToDate Facebook" />
          </li>
          <li class="social-circle twitter">
            <a id="sfiTwitter"
               v-utd-new-tab="true"
               class="wkce-icon-x"
               href="https://twitter.com/UpToDate"
               aria-label="UpToDate Twitter" />
          </li>
          <li class="social-circle youtube">
            <a id="sfiYoutube"
               v-utd-new-tab="true"
               class="wkce-icon-filled-youtube"
               href="https://www.youtube.com/uptodateebm"
               aria-label="UpToDate YouTube" />
          </li>
          <li class="social-circle linkedin">
            <a id="sfiLinkedIn"
               v-utd-new-tab="true"
               class="wkce-icon-filled-linkedin"
               href="https://www.linkedin.com/company/uptodate?trk=hb_tab_compy_id_21924"
               aria-label="UpToDate LinkedIn" />
          </li>
        </ul>
        <utd-copyright-provider :full="true" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import ResearchGuide from '_acaSrc/utility/PendoGuides/ResearchGuide';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdCopyrightProvider
    },
    data() {
        return {
            showResearchGuideLink: false,
            researchGuide: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'isAppDataLoaded',
            'isUccState'
        ]),
        ...mapGetters('footer', [
            'getFooterTitle',
            'getFooterUrl',
            'getFooterTarget',
            'getFooterRel',
            'isTier3Visible',
            'isTier4Visible',
            'isFooterLoaded'
        ]),
        ...mapGetters('search', [ 'isHomeSearchEmpty' ]),
        ...mapGetters('feature', [ 'isHomepageRedesign2024' ]),
        ...mapGetters('device', [
            'isDesktopView',
            'isNotDesktopView',
            'showManageCookiesLink',
            'isMobileOnDesktop'
        ]),
        languageLinkHref(){
            return this.isDesktopView ? '#' : '/settings/change-language';
        },
        languageLinkClickEvent() {
            return this.isDesktopView ? ($event)=> this.showChangeLanguageDialog($event) : null;
        }
    },
    beforeMount() {
        this.setupResearchGuide('research-guides-ready');
    },
    methods: {
        ...mapActions('app', [
            'subscribe',
            'openChangeLanguageDialog'
        ]),
        ...mapActions('footer', [
            'openSupportTag'
        ]),
        setupResearchGuide(eventName) {
            this.researchGuide = new ResearchGuide(eventName);
            // Initial Pendo Research Guide check.
            this.getResearchGuideLink();

            // Subsequent checks (after login, for example)
            this.subscribe({ eventName, handlerFn: this.getResearchGuideLink });
        },
        getResearchGuideLink() {
            this.researchGuide.isShowGuide().then(shouldShow => {
                this.showResearchGuideLink = shouldShow;
            });
        },
        showSupportTag(event) {
            this.openSupportTag({ event });
        },
        showChangeLanguageDialog(event) {
            this.openChangeLanguageDialog(event);
        }
    }
};

</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
.footer {
  .ds1-ph-3();
  .ds1-pv-0();
  .ds1-pb-5();
  background-color: @DS2-UTD-FOOTER-BG-COLOR;
  border-top: @DS1-UTD-GRAY-SINGLE-BORDER;

  * {
    box-sizing: border-box;
  }

  .footer-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .footer-column {
    .ds1-pv-3();
    width: 100%;
    border-bottom: @DS2-UTD-FOOTER-DIVIDER-BORDER-COLOR;

    > ul > li {
      width: 100%;
      display: block;
      .ds1-ma-0();
      color: @DS2-UTD-FOOTER-LINKS-COLOR;

      > a,
      #ot-sdk-btn {
        .ds1-mb-1();
        font-size: 14px;
        line-height: 18px;

        &:focus {
          outline: 2px solid @WKCE-BLACK;
        }
      }

      &:last-child {
        a , #ot-sdk-btn.ot-sdk-show-settings {
        .ds1-mb-0();
        }
      }
    }

    &.socials {
      border-bottom: 0;
      .ds1-pt-3();
      .ds1-pb-0();

      > ul > li {
        width: auto;
        display: inline-block;

        > a {
          .ds1-pa-0();
          .ds1-ma-0();
          font-size: 16px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.social-circle {
          .ds1-pr-4();
          .ds1-mr-0();
        }
      }
    }
  }

  .copyright {
    .ds1-pt-3();
    text-align: left;
    background: none;
    font-size: 12px;
    margin: 0;
    background: none;
    line-height: 1.5;
    height: auto;

    .zh-Hans &,
    .zh-Hant & {
      .ds1-ml-0();
      .ds1-pt-0();
    }
  }
}

// remove this once the font type gets updated with x icons
.wkce-icon-x {
  width: 16px;
  height: 20px;
  display: inline-block;
  background-position: center;
  background: url('~_acaAssets/footer/wk-icon-x.svg') no-repeat;

  &:hover {
    border-bottom: 1px solid @DS2-UTD-FOOTER-LINKS-COLOR;
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    .ds1-pa-0();
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    .footer-row {
      .ds1-ph-3();
      .ds1-pv-2();

      &:first-child {
        justify-content: start;

        .footer-column:not(:last-child) {
          padding-right: 128px;
        }
      }
    }

    .footer-column {
      .ds1-pa-0();
      width: auto;
      border: none;

      &.socials {
        .ds1-pt-2();

        > ul {
          .ds1-pb-2();
        }
      }

      > ul > li {
        .ds1-pa-0();

        > a,
        #ot-sdk-btn.ot-sdk-show-settings {
          .ds1-pv-0();
          .ds1-mb-1();
        }

        &:last-child > a {
          .ds1-mb-0();
        }
      }
    }

    .support,
    .socials {
      width: 100%;
      border-bottom-width: 0;

      > ul > li {
        width: auto;
        display: inline-block;

        > a {
          .ds1-pa-0();
          .ds1-mb-0();
        }

        &:not(:last-child) {
          margin-right: 64px;
        }
      }
    }

    .footer-row:nth-child(2) {
      border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
    }

    &.isUcc .footer-row:nth-child(2) {
      justify-content: flex-end;
    }
  }

  .utdWkHeader.isDesktop #appContainer > footer .copyright {
    .ds1-ma-0();
    .ds1-pt-0();
    .ds1-ml-0();
    text-align: left;
    font-size: 12px;
    background: none;
    line-height: 1.5;
    height: auto;
  }

  .isDesktop #appContainer > footer ul > li {
    .ds1-pr-0();

    &.social-circle {
      .ds1-pr-2();
    }
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    .footer-column {
      width: auto;

      &.socials {
        .ds1-pa-0();
        display: flex;
        flex-direction: row;

        > ul {
          .ds1-pa-0();

          > li {
            .ds1-pl-0();
          }
        }
      }
    }

    .footer-row:last-child {
      justify-content: space-between;
    }
  }

  .utdWkHeader.isDesktop.isHomeSearchEmpty #appContainer > footer .copyright {
    .zh-Hans&,
    .zh-Hant& {
      .ds1-ml-0();
      .ds1-pt-0();
    }
  }
}

.isTablet .footer {
  .ds1-pv-0();
  .ds1-pb-5();

  .footer-row:first-child {
    .ds1-pt-0();
    .ds1-pb-0();
  }

  .footer-row:last-child {
    .ds1-pt-0();
    .ds1-pb-0();
  }

  .footer-row:nth-child(2) {
    border-top: unset;
  }

  .footer-column {
    .ds1-pv-3();
    width: 100%;
    border-bottom: @DS2-UTD-FOOTER-DIVIDER-BORDER-COLOR;

    > ul > li {
      width: 100%;
      .ds1-mb-1();

      > a,
      #ot-sdk-btn {
        margin-top: 7px;
        margin-bottom: 7px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.socials {
      border-bottom: 0;
      display: block;
      .ds1-pt-3();

      &:first-child, &:last-child {
        .ds1-pb-0();
      }

      > ul {
        padding-bottom: 0;
      }

      > ul > li {
        width: auto;
        display: inline-block;

        > a {
          .ds1-pa-0();
          .ds1-ma-0();
          font-size: 16px;
        }

        &.social-circle {
          .ds1-pr-4();
          .ds1-mr-0();
        }
      }
    }
  }
}
</style>
