<template>
  <span v-show="control"
        :class="outerClass"
        tabindex="0"
        role="button"
        aria-label="Clear the input"
        @click="clearFn()"
        @keyup.enter="clearFn()"><!--
      --><i :class="innerClass" /><!--
  --></span>
</template>

<script>
export default {
    props: {
        clearFn: Function,
        control: null,
        outerClass: String,
        innerClass: {
            type: String,
            default: 'fa fa-times-circle'
        }
    }
};
</script>
