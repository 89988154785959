<template>
  <div :id="manuallyExpanded ? 'manually-expanded' : ''"
       class="ai-passage-retrieval-container"
       :class="{'will-overflow': isComponentOverflowed && aiSearchRequestReturned, 
                'no-overflow': !isComponentOverflowed && aiSearchRequestReturned}">
    <div class="ai-passage-retrieval-header">
      <div class="header-text" role="heading" aria-level="2">
        <span> AI Suggested Results </span>
        <span> BETA </span>
        <a class="learn-more wkce-icon-info" />
      </div>     
      <div v-if="shouldShowResults" id="desktop-feedback" class="feedback-bubble">
        <span> Feedback </span>
        <ai-feedback survey-path="/jfe/form/SV_cLVtzIv6BGHXgX4"
                     :survey-meta="getSurveyMeta" />
      </div>
    </div>
    <div v-if="shouldShowSpinner" class="loading-content">
      <div class="wkce-icon-spinner wk-spin" />
    </div>
    <div v-else-if="shouldShowDelayMessage"
         class="timeout-container loading-content">
      <div class="wkce-icon-spinner wk-spin" />
      <span v-for="(msg, i) in getErrorMessage('TIMEOUT')" :key="msg + '-timeout-' + i">
        {{ msg }}
      </span>
    </div>
    <div v-else-if="shouldShowResults" class="ai-results">
      <div class="sub-header">
        <span> These are the most relevant </span>
        <span class="bold"> verbatim UpToDate passage(s) </span>
        <span> addressing your search. Click </span>
        <span class="bold"> 'Read More' </span>
        <span> to obtain important context. </span>
      </div>
      <ai-results-container :search-results="searchResults" />
      <div v-if="shouldShowResults" id="mobile-web-feedback" class="feedback-bubble">
        <span> Feedback </span>
        <ai-feedback survey-path="/jfe/form/SV_cLVtzIv6BGHXgX4"
                     :survey-meta="getSurveyMeta" />
      </div>
    </div>
    <div v-else-if="shouldShowError"
         class="error-container"
         :class="checkErrorType">
      <span v-for="(msg, i) in getErrorMessage('GENERIC')" :key="msg + '-generic-' + i">
        {{ msg }}
      </span>
    </div>
    <div class="content-visibility-buttons">
      <button class="show-more-button" 
              @click="showMore()">
        Show More
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TopicLinkTypes } from '_acaSrc/utility/contents/topic/topic';
import { throttleBounce } from '_acaSrc/utility/timers';
import { getWindow, decodeHtml } from '_acaSrc/utility/DOM';
import { SET_AI_COMPONENT_HAS_BEEN_SEEN } from '_acaSrc/store/ai.store';
import { SET_REFRESH_FOR_AI_RESULTS } from '_acaSrc/store/graphic.store';
import {
    setSearchUrlParamsHelper,
    makeRelativeUrl,
    getLeftmostSubdomain
} from '_acaSrc/utility/http';
import AiFeedback from '_acaSrc/components/contents/search/aiSearch/AiFeedback.vue';
import AiResultsContainer from '_acaSrc/components/contents/search/aiSearch/AiResultsContainer.vue';
import { C_AI_SEARCH } from '_acaSrc/utility/constants';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

const inlineLinksSelector = '.topic-snippet a';
const inlineGfxSelector = '.topic-snippet a.graphic';

export default {
    components: { 
        AiFeedback,
        AiResultsContainer
    },
    props: {
        searchResults: {
            type: Array
        },
        error: {
            type: Object,
            default: () => {
                return { hasError: false };
            }
        }
    },
    data() {
        return {
            manuallyExpanded: false,
            componentHeights: {
                scrollHeight: null,
                clientHeight: null
            },
            hasTimedOut: false
        };
    },
    computed: {
        ...mapGetters('search', [ 'searchFor' ]),
        ...mapGetters('ai', [ 'aiSearchRequestReturned' ]),
        ...mapGetters('profile', [ 'userProfile' ]),
        isComponentOverflowed() {
            if (!this.componentHeights ||
                !this.componentHeights.scrollHeight ||
                !this.componentHeights.clientHeight) {
                return;
            }
            return this.componentHeights.scrollHeight -
                   this.componentHeights.clientHeight >=
                   C_AI_SEARCH.RESIZE_OVERFLOW_TOLERANCE_PX;
        },
        shouldShowSpinner() {
            return !this.aiSearchRequestReturned && !this.hasTimedOut;
        },
        shouldShowDelayMessage() {
            return !this.aiSearchRequestReturned && this.hasTimedOut;
        },
        shouldShowResults() {
            return this.aiSearchRequestReturned && !this.error.hasError;
        },
        shouldShowError() {
            return this.aiSearchRequestReturned && this.error.hasError;
        },
        checkErrorType() {
            switch (this.error && this.error.errorType) {
            case C_AI_SEARCH.ERROR_TYPES.QUERY_REJECTION:
                return 'query-rejection';
            case C_AI_SEARCH.ERROR_TYPES.NO_RESULTS:
                return 'no-ai-results';
            case C_AI_SEARCH.ERROR_TYPES.NOT_ENGLISH:
                return 'not-english-language';
            default:
                return null;
            }
        },
        getSnippetsAndTopicSectionIds() {
            if (!this.searchResults) {
                return {};
            }

            const snippetArray = [];
            const topicSectionIds = [];

            this.searchResults.forEach(topic => {
                const curTopicId = topic.topicId || '';
                if (topic.passages) {
                    topic.passages.forEach(passage => {
                        const curSection = passage.sectionId || '';
                        const snippet = passage.snippet && decodeHtml(passage.snippet);
                        if (snippet.length <= 46) {
                            snippetArray.push(
                                `${snippet.slice(3, -3)} - ${curTopicId}`
                            );
                        }
                        else {
                            let stringToAdd = `${snippet.slice(3, 23)}...${snippet.slice(-23, -3)}`;
                            stringToAdd += ` - ${curTopicId}`;

                            snippetArray.push(stringToAdd);
                        }
                        topicSectionIds.push(`${curTopicId}#${curSection}`);
                    });
                }
            });
            
            return {
                snippets: snippetArray.join('. '),
                topicSectionIds: topicSectionIds.join(', ')
            };
        },
        getSurveyMeta() {
            const userProfile = this.userProfile;
            const query = this.searchFor || '';
            const surveyMeta = { query };

            if (userProfile) {
                surveyMeta.utdId = userProfile?.visitor?.userUtdId || '';
                surveyMeta.environment = userProfile?.visitor?.environment || '';
                surveyMeta.accountId = userProfile?.account?.id || '';
            }

            if (getLeftmostSubdomain() === 'qa2www') {
                surveyMeta.environment = 'QA2';
            }
            
            const { snippets = '', topicSectionIds = '' } = this.getSnippetsAndTopicSectionIds;
            surveyMeta.snippets = snippets;
            surveyMeta.topicSectionIds = topicSectionIds;

            return surveyMeta;
        }
    },
    watch: {
        aiSearchRequestReturned: {
            handler() {
                this.$nextTick(() => {
                    this.processResults();
                });
            },
            deep: true
        }
    },
    mounted() {
        setTimeout(
            () => this.hasTimedOut = true, C_AI_SEARCH.MAX_TIMEOUT_MS
        );
        this[SET_REFRESH_FOR_AI_RESULTS](false);
        this[SET_AI_COMPONENT_HAS_BEEN_SEEN](true);
        if (this.aiSearchRequestReturned) {
            this.resizeCheck();
            this.processResults();
        }
    },
    beforeUnmount() {
        Array.from(this.$el.querySelectorAll(inlineGfxSelector))
            .forEach(el => {
                el.removeEventListener('click', this.onClickInlineGraphic);
            });
    },
    methods: {
        ...mapActions('graphic', [ 'handleUseGraphicLink', 'updateGfxHoverTooltipBinding' ]),
        ...mapMutations('graphic', [ 'SET_REFRESH_FOR_AI_RESULTS' ]),
        ...mapMutations('ai', [ 'SET_AI_COMPONENT_HAS_BEEN_SEEN' ]),
        setupIcons() {
            Array.from(this.$el.querySelectorAll(inlineLinksSelector))
                .forEach(link => {
                    if (TopicLinkTypes.isExternal(link)) {
                        link.classList.add('ds1-inline-svg-link', 'external-link');
                    }
                    else if (TopicLinkTypes.isPathway(link)) {
                        link.classList.add('ds1-inline-svg-link', 'related-pathway-link');
                    }
                });
        },
        async onClickInlineGraphic(evt) {
            await this.handleUseGraphicLink({ evt,
                options: {
                    isFromCollection: true,
                    skipSidebarStateReset: false
                } });
        },
        addGraphicLinkQps() {
            const snippets = this?.$el?.querySelectorAll('.snippet');
            if (!snippets) {
                return;
            }
            // eslint-disable-next-line complexity
            Array.from(snippets).forEach(snippet => {
                const graphicLinks = snippet?.querySelectorAll('a.graphic');
                if (!graphicLinks) {
                    return;
                }

                for (let i = 0; i < graphicLinks.length; i++) {
                    const params = {
                        usageType: C_AI_SEARCH.EVENTS.USAGE_TYPE,
                        sourceValue: C_AI_SEARCH.EVENTS.TOPIC_SOURCE,
                        section: snippet?.getAttribute('section') || '',
                        sectionValue: snippet?.getAttribute('section-id') || '',
                        rankIndex: snippet?.getAttribute('display-rank') || '',
                        searchRank: snippet?.getAttribute('search-rank') || ''
                    };

                    const newUrl = setSearchUrlParamsHelper(graphicLinks[i].href, params);
                    graphicLinks[i].href = makeRelativeUrl(newUrl) || '';
                }
            });
        },
        processResults() {
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.setupIcons();
                    this.addGraphicLinkQps();
                });
                Array.from(this.$el.querySelectorAll(inlineGfxSelector))
                    .forEach(el => {
                        el.addEventListener('click', this.onClickInlineGraphic);
                    });
                this.resizeCheck();
                this.resizeDebounce = throttleBounce(
                    () => this.resizeCheck(),
                    'ai-search-results-resize',
                    200
                );
                this.resizeListener = this.setResizeListener(getWindow(), this.resizeDebounce);
                this[SET_REFRESH_FOR_AI_RESULTS](true);
            });
        },
        resizeCheck() {
            const component = this.$el;
            if (!component || !component.scrollHeight || !component.clientHeight) {
                return;
            } 
            this.componentHeights.scrollHeight = component.scrollHeight;
            this.componentHeights.clientHeight = component.clientHeight;
        },
        getErrorMessage(type) {
            return C_AI_SEARCH.ERROR_TEXT[type];
        },
        showMore() {
            this.logUiClickEvent('NLP_SEARCH'); 
            this.manuallyExpanded = true;
        },
        logUiClickEvent(element) {
            logUiClickEventHelper({
                uiElementName: element,
                optData: 'ShowMore',
                searchTerm: this.searchFor || ''
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.ai-passage-retrieval-container {
  .ds1-pa-2();
  position: relative;
  height: 25vh;
  max-height: 318px;
  min-height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: @WKCE-BLUE-TINT5;
  border: solid @WKCE-GRAY-TINT4;
  border-width: 1px 0;

  .feedback-bubble {
    .ds1-pl-2();
    .ds1-pr-2();
    margin-left: auto;
    width: 176px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @WKCE-BLUE-SHADE1;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
    box-sizing: border-box;

    &#mobile-web-feedback {
      > span {
        color: @WKCE-GRAY-SHADE1;
        font-weight: 400;
      }

      :deep(.thumb-controls > a) {
        border: 1px solid @WKCE-BLUE-TINT2;
        border-radius: 8px;
        background: @WKCE-BLUE-TINT6;
      }
    }
  }
  
  #desktop-feedback {
    display: none;
  }

  .ai-results {
    display: flex;
    flex-direction: column;
  }

  .sub-header {
    .ds1-mt-compact();
    font-style: italic;
    font-size: 12px;
    line-height: 18px;

    .bold {
      font-weight: 600;
    }
  }
  
  .content-visibility-buttons {
    display: flex;
    justify-content: center;

    .show-more-button, .show-less-button {
      justify-content: center;
      align-items: center;
      width: 458px;
      height: 32px;
      background: @WKCE-WHITE;
      color: @WKCE-BLUE-SHADE1;
      border: 1px solid @WKCE-BLUE-TINT3;
      border-radius: 10px;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
  
    .show-more-button {
      display: flex;
      pointer-events: none;
      position: absolute;
      left: 50%;
      bottom: 12px;
      transform: translate(-50%, 0);
      z-index: 1;
    }
  
    .show-less-button {
      display: none;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
    background:linear-gradient(transparent calc(25vh - 40px), #DBEBF4);
  }

  &.will-overflow {
    &::before, .show-more-button, .show-less-button {
      opacity: 1;
    }

    .show-more-button {
      color: @WKCE-BLUE-SHADE1;
      pointer-events: unset;
      transition: border-color 0.25s ease-in-out,
                  background-color 0.25s ease-in-out;

      &:hover {
        background: @WKCE-BLUE-TINT3;
        color: @WKCE-BLUE-SHADE2;
      }
    }
  }

  &#manually-expanded {
    max-height: 100%;
    height: 100%;

    &::before, .show-more-button {
      opacity: 0;
      pointer-events: none;
    }

    .show-more-button {
      color: transparent;
    }

    .show-less-button {
      .ds1-mt-2();
      display: flex;
      align-self: center;
      opacity: 1;
    }
  }

  .error-container, .timeout-container {
    margin: auto;
    display: flex;
    flex-direction: column;

    > span {
      text-align: center;
    }
  }

  .timeout-container > span {
    .ds1-mt-2();
  }

  .loading-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25vh;

    .wkce-icon-spinner {
      font-size: 75px;
      width: 75px;
      height: 75px;
      color: @WKCE-BLUE;
    }
  }

  .ai-passage-retrieval-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 25.6px;
    max-width: 1868px;
    gap: 16px; // Not IE11 supported, minor cosmetic tweak

    .header-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: @WKCE-GRAY-SHADE1;

      .learn-more {
        .ds1-ml-1();
        width: 14px;
        height: 14px;
        vertical-align: -2px;
        color: @WKCE-GREEN-SHADE1;
        font-weight: 500;
        font-size: 14px;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
      
      &::before {
        .ds1-mr-1();
        content: "";
        background: url(~_acaAssets/search/ai-stethoscope.svg);
        background-size: 20px 24px;
        width: 20px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
      }

      > span:nth-child(2) {
        .ds1-ml-compact();
        color: @WKCE-GREEN-SHADE1;
        font-size: 11px;
      }
    }
  }
}

@media only screen and (min-width: 768px) { 
  .isDesktop .ai-passage-retrieval-container {
    .ds1-mb-2();
    padding: 16px 26px;

    #mobile-web-feedback {
      display: none;
    }

    #desktop-feedback {
      display: flex;
    }

    .ai-passage-retrieval-header .feedback-bubble {
      height: 22px;
      width: 144px;
      margin-top: 0;
      margin-left: 0;
      border: 1px solid @WKCE-BLUE-TINT2;
      background: @WKCE-BLUE-TINT6;
    }

    .sub-header {
      margin-left: 33px;
    }
  }
}
</style>